<template>
  <div class="views-about">
    <div class="about-header">
      <el-carousel
        :autoplay="false"
        indicator-position="none"
        arrow="never"
        class="carousel_box"
        ref="Banner"
      >
        <el-carousel-item v-for="(item, index) in Banner" :key="index">
          <img class="banner_img" v-lazy="item" fit="cover" alt="" />
        </el-carousel-item>
      </el-carousel>
      <div class="about-tabs">
        <div class="tabs-content">
          <div
            class="tabs-item"
            :class="{ active: item.name == active }"
            v-for="(item, index) in tabList"
            :key="index"
            @click="onTabClick(item, index)"
          >
            <span>{{ item.title }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="about-content">
      <hd-about v-if="active == 'about'" />
      <hd-join v-if="active == 'joinus'" />
      <hd-concat v-if="active == 'concat'" />
    </div>
  </div>
</template>
<script>
import HdAbout from "@/components/about/HdAbout.vue";
import HdJoin from "@/components/about/HdJoin.vue";
import HdConcat from "@/components/about/HdConcat.vue";
import WOW from "wowjs";
export default {
  name: "views-about",
  components: { HdAbout, HdJoin, HdConcat },
  data() {
    return {
      urlList: [
        "@/assets/images/about/about3.png",
        "@/assets/images/about/about3.png",
        "@/assets/images/about/about3.png",
      ],
      bannerIndex: 0,
      Banner: [
        require("@/assets/images/about/about1.png"),
        require("@/assets/images/about/about4.png"),
        require("@/assets/images/about/about3.png"),
      ],
      tabList: [
        {
          name: "about",
          title: "关于航动",
        },
        {
          name: "joinus",
          title: "加入我们",
        },
        {
          name: "concat",
          title: "联系我们",
        },
      ],
      active: "about",
    };
  },
  methods: {
    onTabClick(item, index) {
      this.active = item.name;
      this.$refs.Banner.setActiveItem(index);
    },
  },
  mounted() {
    this.$nextTick(() => {
      let wow = new WOW.WOW({
        boxClass: "wow",
        animateClass: "animated",
        offset: 0,
        mobile: true,
        live: false,
      });
      wow.init();
    });
  },
};
</script>
<style lang="less" scoped>
.views-about {
  // width: 91.66%;
  width: 100%;
  margin: auto;
  margin-top: 72px;
  .about-header {
    .carousel_box {
      height: 500px;
      z-index: -1;
      /deep/.el-carousel__container {
        height: 100%;
      }
    }
    position: relative;
    .banner_img {
      width: 100%;
      height: 100%;
    }
  }

  .about-tabs {
    position: absolute;
    bottom: -0.5rem;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    border-radius: 4px;
    display: flex;
    // height: 18%;
    height: 1rem;
    justify-content: center;
    background: #fff;
    box-shadow: 0px 0px 20px 0px rgba(31, 99, 210, 0.2);

    .tabs-content {
      display: flex;
      justify-content: center;
      align-items: center;
      //width: 50%;
      height: 100%;
    }

    .tabs-item {
      // width: 50%;
      padding: 0 70px;
      //border-radius: 1px solid #;
      font-size: 0.225rem;
      color: #333;
      cursor: pointer;

      &.active {
        color: #618cde;
      }

      &.tabs-item:nth-child(2) {
        border-left: 1px solid #618cde;
        border-right: 1px solid #618cde;
      }
    }
  }

  .about-content {
    margin: 100px auto 0;
  }
}
@media screen and (max-width: 992px) {
  .views-about {
    .about-header {
      .carousel_box {
        height: 426px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .views-about {
    .about-header {
      .carousel_box {
        height: 350px;
      }
    }
  }
  .tabs-item {
    font-size: 10px !important;
    padding: 0 20px !important;
  }
}
@media screen and (max-width: 480px) {
  .about-header {
    .carousel_box {
      height: 180px !important;
    }
  }
}
</style>
