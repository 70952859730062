<template>
  <div class="components-about-hdConcat">
    <el-row>
      <p class="smallFont">联系我们</p>
      <el-row
        class="concat-content wow fadeInLeft"
        data-wow-duration="0.8s"
        data-wow-delay="0.2s"
      >
        <el-col :xs="24" :sm="12">
          <div class="left concat-card">
            <div class="left_box">
              <h2>上海总部</h2>
              <p>电话：021-62561666</p>
              <p>
                地址：上海市徐汇区宜山路700号普天信息产业园A7幢502室/1107-1108室
              </p>
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="12">
          <div class="right concat-card">
            <div class="right_box">
              <p>
                商务合作：<a href="mailto:market@npnets.com"
                  >market@npnets.com</a
                >
              </p>
              <p>
                技术支持：<a href="mailto:service@npnets.com"
                  >service@npnets.com</a
                >
              </p>
              <p>
                运营支撑：<a href="mailto:support@npnets.com"
                  >support@npnets.com</a
                >
              </p>
              <p>
                加入我们：<a href="mailto:hrzp@npnets.com">hrzp@npnets.com</a>
              </p>
            </div>
          </div>
        </el-col>
      </el-row>
      <div></div>
    </el-row>
    <el-row
      class="city-wrapper  wow fadeInUp"
      data-wow-duration="0.8s"
      data-wow-delay="0.2s"
    >
      <el-col style="width: auto">
        <!-- <div class="label">城市：&nbsp;&nbsp;</div> -->
        <div class="label">城市:</div>
      </el-col>
      <el-col>
        <tab @change="tabChange" :tabList="tabList" class="tabItem"></tab>
      </el-col>
    </el-row>

    <baidu-map
      class="bm-view wow fadeInUp"
      data-wow-duration="0.8s"
      data-wow-delay="0.2s"
      :center="address"
      :zoom="18"
      @zoomend="zoomend"
      ak="MMzu0V8Et7KaWVMCuO4pgCNLWIlsUANN"
    >
      <BmMarker :position="address" animation="BMAP_ANIMATION_BOUNCE">
        <div class="container">
          <BmOverlay
            pane="markerPane"
            @initialize="initialize"
            :class="{ sample: true }"
          >
            <div class="content">
              <strong>{{ title }}</strong>
              <div class="activeContent">{{ content }}</div>
              <div>{{ tel }}</div>
            </div>
            <!--斜三角-->
            <div id="triangle-down"></div>
          </BmOverlay>
        </div>
      </BmMarker>
    </baidu-map>
    <pageMore :newsLis="newsLis"></pageMore>
  </div>
</template>

<script>
import WOW from "wowjs";
import tab from "../../views/tab";
import BaiduMap from "vue-baidu-map/components/map/Map.vue";
import BmMarker from "vue-baidu-map/components/overlays/Marker";
// import BmInfoWindow from 'vue-baidu-map/components/overlays/InfoWindow'
import BmOverlay from "vue-baidu-map/components/overlays/Overlay";
import cityList from "@/assets/mixin/city";
import pageMore from "@/components/common/pageMore.vue";

export default {
  name: "components-about-hdConcat",
  mixins: [cityList],
  components: {
    pageMore,
  },
  data: function() {
    return {
      newsLis: [
        {
          name: "news",
          textImg: require("@/assets/images/icon-foot.png"),
        },
      ],
      tabList: [
        [
          "江苏",
          "河北",
          "河南",
          "湖北",
          "湖南",
          "广西",
          "江西",
          "四川",
          "陕西",
          "吉林",
        ],
        ["鹤壁", "衡水", "肇庆", "镇江", "武汉"],
      ],
      address: {},
      tel: "15637116305",
      title: "南京运营中心",
      content: "",
      map: null,
      BMap: null,
      el: "",
      DowntownDetails: "南京市雨花台区花神大道23号京妆商务5号楼406室",
      province: "江苏",
    };
  },
  components: {
    tab,
    BaiduMap,
    BmMarker,
    BmOverlay,
    pageMore,
  },
  mounted() {
    this.$nextTick(() => {
      let wow = new WOW.WOW({
        boxClass: "wow",
        animateClass: "animated",
        offset: 0,
        mobile: true,
        live: false,
      });
      wow.init();
    });
  },
  created() {},
  methods: {
    tabChange(index, label) {
      this.cityList.forEach((ele, index) => {
        if (ele.cityname == label) {
          this.title = ele.title;
          this.DowntownDetails = ele.address;
          this.tel = ele.tel;
        }
      });
      this.province = label;
      if (this.BMap !== null) {
        this.setAddress(this.BMap);
      }
    },
    initialize({ el, BMap, map }) {
      this.BMap = BMap;
      this.map = map;
      this.el = el;
      this.setAddress(this.BMap);
    },
    // readyEvent({ BMap }) {
    //   this.map = BMap
    //   this.setAddress(this.map)
    // },
    setAddress() {
      const myGeoLoad = new this.BMap.Geocoder();
      myGeoLoad.getPoint(
        this.DowntownDetails,
        (point, e) => {
          if (point) {
            this.address = point;
            // this.content = e.city + e.address;
            this.content = e.address;
          }
        },
        this.province
      );
      const pixel = this.map.pointToOverlayPixel(
        new this.BMap.Point(this.address.lng, this.address.lat)
      );
      this.el.style.left = pixel.x - 200 + "px";
      this.el.style.top = pixel.y - 135 + "px";
    },
    zoomend() {
      if (this.BMap !== null) {
        this.setAddress();
      }
    },
  },
};
</script>
<style lang="less" scoped>
.components-about-hdConcat {
  .smallFont {
    // font-size: 0.375rem;
    // margin: 0.3125rem 0;
    font-size: 0.45rem;
    font-weight: 600;
    color: #333333;
    margin-bottom: 0.625rem;
  }
  .concat-content {
    margin-left: 4%;
    margin-right: 4%;
    .el-col {
      display: flex;
      justify-content: center;
      align-items: center;

      .concat-card {
        border-radius: 10px;
        text-align: left;
        width: 40.6vw;
        height: 21.6vw;

        &.left {
          background: url(../../assets/images/shanghaizongbu.png) no-repeat top
            center;
          background-size: cover;
          padding: 3.6458vw 0 0 3.125vw;

          .left_box {
            h2 {
              height: 28px;
              font-size: 0.375rem;

              font-weight: bold;
              color: #333333;
              line-height: 0.45rem;
              margin-bottom: 2.2917vw;
            }

            p {
              width: 55%;

              font-size: 0.2rem;
              font-weight: 500;
              color: #333333;
            }

            p:last-child {
              margin-top: 1.3021vw;
              line-height: 0.375rem;
            }
          }
        }

        &.right {
          background: url(../../assets/images/shangwulianxi.png) no-repeat top
            center;
          background-size: cover;
          padding: 4.4271vw 0 0 3.125vw;

          .right_box {
            p {
              font-size: 0.2rem;
              margin-top: 1.9792vw;

              font-weight: 500;
              color: #333333;

              &:first-child {
                margin-top: 0;
              }

              a {
                color: #5e89dc;
              }
            }
          }
        }
      }
    }
  }

  .smallFont {
    font-weight: bold;
  }

  .city-wrapper {
    display: flex;
    // margin: 5.2083vw 0 1.5625vw;
    margin: 5.2083vw 0 0;
    text-align: center;
    margin-right: 4%;
    margin-left: 4%;

    .label {
      //width: 50px;
      text-align: left;
      white-space: nowrap;
      margin-left: 5%;
      width: auto;
      margin-right: 14px;
    }

    .tabItem {
      // margin-top: -0.75rem;
    }
  }
  .label {
    font-size: 0.25rem;
  }

  .tabItem .item {
    font-size: 15px;
  }
}

.bm-view {
  width: 83.3333vw;
  height: 26.0417vw;
  margin: 0 auto 5.9896vw;

  .container {
    //background-color: #1c5cc2;
    .active {
      background-color: #1c5cc2;
      text-align: center;

      .activeContent {
        font-size: 12px;
      }
    }
  }
}

.sample {
  background: #2a6bd4;
  box-shadow: 0px 0px 10px 0px rgba(42, 107, 212, 0.2);
  opacity: 0.85;

  display: inline-flex;

  width: 450px;
  height: 100px;
  color: #fff;
  text-align: center;
  padding: 10px;
  position: absolute;
  .content {
    margin: auto;
    strong {
      font-size: 16px;
      font-weight: 500;
    }
    div {
      font-size: 12px;
    }
  }
}

#triangle-down {
  position: absolute;
  bottom: -29px;
  left: 202px;
  width: 0;
  height: 0;
  border-left: 0px solid transparent;
  border-right: 20px solid transparent;
  border-top: 36px solid #2b6bd5;
  transform: rotate(18deg);
}

.tabMdItem .item {
  font-size: 15px;
  margin: 10px 50px;
}

@media screen and (max-width: 767px) {
  .components-about-hdConcat {
    .concat-content {
      .el-col {
        margin-top: 0.3125rem;
        .concat-card {
          height: 43vw;
          width: 80vw;
          padding-top: 0 !important;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          .right_box {
            height: 70%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            p {
              margin-top: 0 !important;
            }
          }
        }
      }
    }
    .sample {
      transform: scale(0.7);
    }
    .smallFont {
      margin-top: -50px;
    }
  }

  .bm-view {
    height: 40vw;
  }
}
</style>
