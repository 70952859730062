<template>
  <div class="components-about-hdAbout">
    <el-row class="intro">
      <el-col
        :xs="24"
        :sm="24"
        :md="9"
        class="imageItem  hidden-sm-and-down wow bounceInLeft"
        data-wow-duration="1.5s"
        data-wow-delay="0.1s"
      >
        <img v-lazy="build[0]" alt="" />
      </el-col>
      <el-col
        :xs="24"
        :sm="24"
        :md="9"
        class="imageSmall hidden-md-and-up wow bounceInLeft"
        data-wow-duration="1.5s"
        data-wow-delay="0.1s"
      >
        <img v-lazy="buildSmall[0]" alt="" />
      </el-col>
      <el-col
        class="companyIntro wow bounceInRight"
        :xs="24"
        :sm="24"
        :md="15"
        data-wow-duration="1.5s"
        data-wow-delay="0.1s"
      >
        <div class="content">
          <h1>公司介绍</h1>
          <div class="des">
            <p>
              上海航动科技有限公司创立于2011年，凭借智能语音客户服务积累的优势资源，致力于打造为企业及用户提供个性化权益服务的客户微生态运营平台，成为国内提供数智权益增值服务的主流平台。依托互联网、人工智能及大数据核心技术，公司目前主要提供智能业务运营系统、坐席运营管理系统、语音AI调度系统、智能外呼系统、智能权益运营系统和大数据生产系统6大服务系统。
            </p>
            <p>
              上海航动科技有限公司深耕智慧服务行业10余年，平台资源遍布全国，有专业坐席10万+和自主知识产权50+项。
            </p>
            <p>
              公司业务辐射全国18省市自治区，包含3大事业部、5大运营基地以及权益中心。服务客户涵盖运营商、金融、互联网、教育、医疗、出行等多领域龙头企业，并通过云服务、权益覆盖海量中小企业。
            </p>
          </div>
          <el-row class="numList">
            <el-col
              :xs="12"
              :sm="12"
              :md="6"
              class="numItem"
              v-for="(item, index) in numList"
              :key="index"
            >
              <div class="nums">
                {{ item.num }}<span>{{ item.sub }}</span>
              </div>
              <div class="title">{{ item.title }}</div>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
    <!--企业文化小屏幕下显示-->
    <div
      class="cultureSmall hidden-md-and-up wow fadeInUp"
      data-wow-duration="0.8s"
      data-wow-delay="0.4s"
    >
      <h1>企业文化</h1>
      <el-row class="smallItem">
        <el-col
          :xs="24"
          :sm="24"
          :md="12"
          v-for="(item, index) in cultureList"
          :key="index"
          :class="{ active: index == active }"
          @mouseover.native="onMouseover(index)"
        >
          <div class="container">
            <el-image :src="item.culture" class="imgStyle" lazy></el-image>
          </div>
          <i></i>
          <span class="head-title">{{ item.title }}</span>
        </el-col>
      </el-row>
    </div>
    <!--企业文化大屏幕下显示-->
    <div
      class="cultureColor hidden-sm-and-down wow fadeInUp"
      data-wow-duration="0.8s"
      data-wow-delay="0.4s"
    >
      <div class="culture">
        <h1 class="companyCulture">企业文化</h1>
        <el-row class="culture-wrapper">
          <el-col :md="9" class="wrapper-left">
            <el-button-group class="opt-btns">
              <el-button
                :class="cultureBtnActive === 'prev' ? 'cultureBtnActive' : ''"
                @click="addEvent1"
                round
                icon="el-icon-arrow-up"
              ></el-button>
              <el-button
                @click="cutEvent1"
                round
                :class="cultureBtnActive === 'next' ? 'cultureBtnActive' : ''"
                icon="el-icon-arrow-down"
              ></el-button>
            </el-button-group>
            <div class="culture-content">
              <div class="titleColor">
                <div class="padding_left en">{{ text.word }}</div>
                <div class="">
                  <div class="padding_left title  ">{{ text.title }}</div>
                  <div class="padding_left desc ">{{ text.des }}</div>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :md="15">
            <div class="wrapper-right hidden-sm-and-down">
              <div class="circle-left">
                <div class="circle">
                  <div class="circle1">
                    <!--动画盒子-->
                    <div class="SmallCircle_box">
                      <span class="SmallCircle">
                        <i>
                          <span></span>
                        </i>
                      </span>
                    </div>
                    <div class="circle2">
                      <img v-lazy="circle" />
                    </div>
                  </div>
                </div>
              </div>

              <ul class="cultureList">
                <li
                  :class="{ active: index == Index }"
                  v-for="(item, index) in cultureList"
                  :key="index"
                  class="wow bounceInRight"
                  :data-wow-duration="index === 0 ? '0s' : '0.8s'"
                  :data-wow-delay="0.3 * index + 's'"
                >
                  <!-- @click="onCultureClick(index)" -->
                  <el-image :src="item.culture" lazy></el-image>
                  <span>{{ item.title }}</span>
                </li>
              </ul>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- 发展历程小屏幕展示 -->
    <div
      class="history2 hidden-md-and-up wow fadeInUp"
      data-wow-duration="0.8s"
      data-wow-delay="0.4s"
    >
      <h1>发展历程</h1>
      <el-row>
        <el-col>
          <tab-list
            @change="onYearClick_h"
            :InitialIndex="1"
            :tab-list="[2021, 2020, 2019, 2018, 2017, 2016, 2015, 2014]"
            class="history_tabList"
          ></tab-list>
        </el-col>
        <el-col style="margin-top: 0.75rem ">
          <el-carousel
            class="elCarousel"
            arrow="never"
            direction="horizontal"
            style="height: 100%"
            :autoplay="false"
            indicator-position="none"
            ref="carousel_h"
            :initial-index="1"
          >
            <el-carousel-item
              class="item"
              v-for="(item, index) in histroyList"
              :key="index"
            >
              <div
                class="carousel_box"
                v-show="sm_histroyActive === index"
                v-for="(itemSub, indexSub) in item.contentList"
                :key="indexSub"
              >
                <el-col class="imgItem" :xs="24" :sm="24">
                  <el-carousel arrow="never">
                    <el-carousel-item
                      v-for="(itemSubChild, indexSubChild) in item.imgList"
                      :key="indexSubChild"
                      style="height: 100%;width: 100%"
                    >
                      <img
                        style="height: 100%;width: 100%"
                        v-lazy="itemSubChild"
                      />
                    </el-carousel-item>
                  </el-carousel>
                </el-col>
              </div>
            </el-carousel-item>
          </el-carousel>
          <ul class="contentList">
            <li
              v-for="(itemSubChild, indexSubChild) in histroyList[
                sm_histroyActive
              ].contentList"
              :key="indexSubChild"
            >
              <span class="month">{{ itemSubChild.month }}月</span>
              {{ itemSubChild.des }}
            </li>
          </ul>
        </el-col>
      </el-row>
    </div>
    <!-- 发展历程大屏幕展示 -->
    <div
      class="history hidden-sm-and-down wow fadeInUp"
      data-wow-duration="0.8s"
      data-wow-delay="0.4s"
    >
      <h1>发展历程</h1>
      <el-row class="histroy-wrapper">
        <el-col :md="1" class="history-left hidden-sm-and-down">
          <div class="btn_item">
            <el-button
              :class="['prev-btn']"
              @click="addEvent"
              round
              icon="el-icon-arrow-up"
            ></el-button>
          </div>
          <div class="year-wrapper">
            <ul>
              <li
                v-for="(item, index) in histroyList"
                :class="{ active: histroyActive === index }"
                :key="index"
                @click="onYearClick(index)"
              >
                {{ item.year }}
              </li>
            </ul>
          </div>
          <div class="btn_item">
            <el-button
              @click="cutEvent"
              round
              :class="['next-btn']"
              icon="el-icon-arrow-down"
            ></el-button>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="23" class="histroy-wrapper-col">
          <el-row style="height: 100%">
            <el-carousel
              arrow="never"
              direction="vertical"
              style="height: 100%"
              :autoplay="false"
              indicator-position="none"
              :initial-index="1"
              ref="carousel"
            >
              <el-carousel-item
                class="item"
                v-for="(item, index) in histroyList"
                :key="index"
                style="height: 100%"
              >
                <div
                  style="height: 100%"
                  class="carousel_box"
                  v-for="(itemSub, indexSub) in item.contentList"
                  :key="indexSub"
                >
                  <el-col style="height: 100%" :xs="24" :sm="24" :md="13">
                    <el-carousel arrow="never" style="height: 100%">
                      <el-carousel-item
                        v-for="(itemSubChild, indexSubChild) in item.imgList"
                        :key="indexSubChild"
                        style="height: 100%"
                      >
                        <img
                          v-lazy="itemSubChild"
                          style="width: 100%;height: 100%"
                        />
                      </el-carousel-item>
                    </el-carousel>
                  </el-col>
                  <el-col style="height: 100%" :xs="24" :sm="24" :md="11">
                    <ul class="contentList">
                      <li
                        v-for="(itemSubChild,
                        indexSubChild) in item.contentList"
                        :key="indexSubChild"
                      >
                        <span class="month">{{ itemSubChild.month }}月</span>
                        {{ itemSubChild.des }}
                      </li>
                    </ul>

                    <p>{{ item.prevYear }}</p>
                  </el-col>
                </div>
              </el-carousel-item>
            </el-carousel>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <!--资质荣誉-->
    <div
      class="honor wow fadeInUp"
      data-wow-duration="0.8s"
      data-wow-delay="0.4s"
    >
      <h1>资质荣誉</h1>
      <div class="tabs-content">
        <div
          class="tabs-item"
          :class="{ active: index == IndexItem }"
          v-for="(item, index) in tabList"
          :key="index"
          @click="onTabClick(index)"
        >
          <span>{{ item.title }}</span>
        </div>
      </div>
      <!--小屏幕下显示-->
      <el-row
        class="hidden-Wrapper hidden-md-and-up"
        v-for="(item, index) in honorList"
        :key="'h' + index"
        v-show="index == IndexItem"
      >
        <el-col
          class="hidden-left"
          style="margin: 0.75rem 0"
          :xs="24"
          :sm="24"
          :md="12"
        >
          <el-carousel
            :interval="4000"
            type="card"
            height="200px"
            indicator-position="none"
          >
            <el-carousel-item v-for="(key, value) in item.pic" :key="value">
              <img v-lazy="key.imgSrc" alt="" style="width:100%;height: 100%" />
            </el-carousel-item>
          </el-carousel>
        </el-col>
      </el-row>
      <!--大屏幕下显示-->
      <el-row
        class="hidden-sm-and-down"
        :class="{ 'honor-wrapper': index === IndexItem }"
        v-for="(item, index) in honorList"
        :key="index"
      >
        <div style="width: 100%;height: 100%" v-if="index === IndexItem">
          <el-col class="honor-left" :md="8">
            <ul>
              <li class="year">
                <span>{{ honorInfo.year }}</span
                ><span class="yearColor">年</span>
              </li>
              <li class="title">
                {{ honorInfo.title }} <span class="line"></span>
              </li>
              <li class="des">{{ honorInfo.des }}</li>
            </ul>
            <div class="opt-btns">
              <el-button
                icon="el-icon-arrow-left"
                circle
                @click="onHonorPrev(index)"
                :class="honorBtnActive === 'prev' ? 'honorBtnActive' : ''"
                style="color: #1f63d2"
              ></el-button>
              <el-button
                icon="el-icon-arrow-right"
                circle
                @click="onHonorNext(index)"
                :class="honorBtnActive === 'next' ? 'honorBtnActive' : ''"
                style="color: #1f63d2"
              ></el-button>
            </div>
          </el-col>
          <el-col class="honor-right animation" :md="16">
            <honor-swiper
              :imgList="item.pic"
              :ref="'mySwiper' + index"
              @change="handleHonorSwiper"
              :index="index"
            ></honor-swiper>
            <!--          <div class="active">-->
            <!--             &lt;!&ndash; <el-image :src="picBackList"></el-image>  &ndash;&gt;-->
            <!--          <div class="backPic" :class="index !== 0 ? 'backTwoOrThree' : ''">-->
            <!--              <img :src="item.pic" />-->
            <!--            </div>-->

            <!--            <div class="activeITem"></div>-->
            <!--          </div>-->
            <!--          <div class="next">-->
            <!--            <img :src="item.pic" />-->
            <!--          </div>-->
          </el-col>
        </div>
      </el-row>
    </div>
    <pageMore :newsLis="newsLis"></pageMore>
  </div>
</template>
<script>
import WOW from "wowjs";
import tabList from "../../views/tabList/index";
import honorSwiper from "./honorSwiper/index";
import pageMore from "@/components/common/pageMore.vue";
export default {
  name: "components-about-hdAbout",
  components: {
    tabList,
    honorSwiper,
    pageMore,
  },
  data() {
    return {
      activeColor: false,
      active: 0,
      Index: 0,
      IndexItem: 1,
      text: "",
      textItem: "",
      currentIndex: 0,
      date: "2017",
      adc: {
        name: "tt",
        age: "17",
        gg: "172cm",
      },
      value: "",
      newsLis: [
        {
          name: "news",
          textImg: require("@/assets/images/icon-foot.png"),
        },
      ],
      currentTime: null,
      build: [require("@/assets/images/other/Company introduction.png")],
      buildSmall: [require("@/assets/build.png")],
      circle: require("@/assets/visionBig.png"),
      numList: [
        {
          num: 10,
          title: "服务行业10余年",
        },
        {
          num: 10,
          sub: "万+",
          title: "专业坐席10万+",
        },
        {
          num: 50,
          sub: "+",
          title: "知识产权50+项",
        },
        {
          num: 18,
          title: "业务辐射全国18省市自治区",
        },
      ],
      cultureBtnActive: "",
      cultureIndex: 0,
      cultureList: [
        {
          title: "使命",
          word: "Mission",
          culture: require("@/assets/mission.png"),
          des: "科技创新让AI有温度",
        },
        {
          title: "愿景",
          word: "Vision",
          culture: require("@/assets/vision.png"),
          des: "打造一流的数字服务生态圈",
        },
        {
          title: "核心价值观",
          word: "Core Values",
          culture: require("@/assets/values.png"),
          des: "诚信守诺  以人为本  共同成长 合作创新",
        },
      ],
      histroyActive: 1,
      sm_histroyActive: 0,
      histroyList: [
        {
          year: 2021,
          imgList: [require("@/assets/images/development/2021.png")],
          contentList: [
            {
              month: 3,
              des: "智能权益系统1.0上线",
              prevYear: 2020,
            },
          ],
          prevYear: 2020,
        },
        {
          year: 2020,
          imgList: [require("@/assets/images/development/2020.png")],
          contentList: [
            {
              month: 12,
              des: "获“2020新时代中国经济创新企业”荣誉",
            },
            {
              month: 12,
              des: "获“上海软件和信息技术服务业高成长百家”荣誉",
            },
            {
              month: 11,
              des: "获CMMI5级认证",
            },
            {
              month: 10,
              des: "增城、鹤壁、衡水3大基地揭牌运营",
            },
          ],
          prevYear: 2019,
        },
        {
          year: 2019,
          imgList: [require("@/assets/images/development/2019.png")],
          contentList: [
            {
              month: 12,
              des: "荣获“人机协同智能云客服平台”",
            },
            {
              month: 11,
              des: "“话倍”智能坐席分身上线",
            },
          ],
          prevYear: 2018,
        },
        {
          year: 2018,
          imgList: [require("@/assets/images/development/2018.png")],
          contentList: [
            {
              month: 11,
              des: "荣获“高新技术企业证书”",
            },
            {
              month: 5,
              des: "智能机器人上线",
            },
          ],
          prevYear: 2017,
        },
        {
          year: 2017,
          imgList: [require("@/assets/images/development/2017.png")],
          contentList: [
            {
              month: 5,
              des: "智能电营坐席数突破20万",
            },
          ],
          prevYear: 2016,
        },
        {
          year: 2016,
          imgList: [require("@/assets/images/development/2016.png")],
          contentList: [
            {
              month: 2,
              des: "成为中国联通金牌合作伙伴",
            },
          ],
          prevYear: 2015,
        },
        {
          year: 2015,
          imgList: [require("@/assets/images/development/2015.png")],
          contentList: [
            {
              month: 2,
              des: "话加1.0上线",
            },
          ],
          prevYear: 2014,
        },
        {
          year: 2011,
          imgList: [require("@/assets/images/development/2011.png")],
          contentList: [
            {
              month: 2,
              des: "公司成立",
            },
          ],
          prevYear: 2010,
        },
      ],
      // tabActive: "qualification",
      tabList: [
        {
          name: "qualification",
          title: "行业资质",
        },
        {
          name: "honor",
          title: "企业荣誉",
        },
        {
          name: "property",
          title: "知识产权",
        },
      ],
      honorActive: 0,
      honorBtnActive: "",
      honorList: [
        {
          pic: [
            {
              title: "CMMI5级",
              des: "软件能力成熟度模型集成",
              year: 2020,
              imgSrc: require("@/assets/images/honor/Qualifications/group9.png"),
            },
            {
              title:
                "航动科技荣获增值电信业务经营许可证&电信网码号资源使用证书",
              des: "呼叫中心业务接入号码&国内呼叫中心业务",
              year: 2020,
              imgSrc: require("@/assets/images/honor/Qualifications/group8.png"),
            },
            {
              title: "航动科技荣获ISO9001和ISO27001",
              des: "安全管理体系认证证书，质量管理体系认证证书",
              year: 2020,
              imgSrc: require("@/assets/images/honor/Qualifications/group10.png"),
            },
          ],
        },
        {
          pic: [
            {
              title: "航动科技荣获上海市云计算应用培育项目",
              des: "人机协同智能云客服平台",
              year: 2019,
              imgSrc: require("@/assets/images/honor/honor1/group1.png"),
            },

            {
              title: "航动科技荣获高新技术企业证书",
              des: "",
              year: 2018,
              imgSrc: require("@/assets/images/honor/honor1/group5.png"),
            },

            {
              title: "航动科技荣誉证书",
              des: "被评为2020年上海软件和信息技术服务业高成长百家",
              year: 2020,
              imgSrc: require("@/assets/images/honor/honor1/group3.png"),
            },

            {
              title: "航动科技荣获上海市高新技术企业协会第二届理事会",
              des: "副会长单位",
              year: 2020,
              imgSrc: require("@/assets/images/honor/honor1/group6.png"),
            },
            {
              title: "航动科技荣获资信等级证书",
              des: "单位信誉等级AAA级",
              year: 2021,
              imgSrc: require("@/assets/images/honor/honor1/group4.png"),
            },
          ],
        },
        {
          pic: [
            {
              title: "航动科技荣获外观设计专利证书",
              des: "用于电脑的图形用户界面（ZL201930474164.1）",
              year: 2019,
              imgSrc: require("@/assets/images/honor/honor1/ZL201930474164-1.png"),
            },
            {
              title: "航动科技荣获外观设计专利证书",
              des: "用于显示屏幕面板的分屏展示信息的图形用户界面（ZL202230394319.2）",
              year: 2019,
              imgSrc: require("@/assets/images/honor/honor1/ZL202230394319-2.png"),
            },
            {
              title: "航动科技荣获发明专利证书",
              des: "一种语音客服自动生成部署语音合成模型的方法（ZL202111585851.3）",
              year: 2019,
              imgSrc: require("@/assets/images/honor/honor1/ZL202111585851-3.png"),
            },
            {
              title: "航动科技荣获软件产品证书",
              des: "航动话+商品订购软件V1.0",
              year: 2018,
              imgSrc: require("@/assets/images/honor/honor1/group2.png"),
            },
            {
              title: "航动科技荣获发明专利证书",
              des: "一种基于人工智能语音业务推送方法、装置与系统",
              year: 2019,
              imgSrc: require("@/assets/images/honor/patent/group13.png"),
            },
            {
              title: "航动科技荣获实用新型专利证书",
              des: "一种基于工控机的呼叫中心设备",
              year: 2015,
              imgSrc: require("@/assets/images/honor/patent/group11.png"),
            },
            {
              title: "航动科技荣获外观设计专业证书",
              des: "用于电脑的图形用户界面",
              year: 2020,
              imgSrc: require("@/assets/images/honor/patent/group12.png"),
            },
            {
              title:
                "航动科技荣获中华人民共和国国家版权局计算机软件著作权登记证书",
              des: "航动话+质检管理软件V1.0",
              year: 2019,
              imgSrc: require("@/assets/images/honor/patent/group14.png"),
            },
          ],
        },
      ],
      realIndex: 0, //实际的轮播index
      honorInfo: {},
      // picBackList: require("@/assets/images/honor/honor1/honor3.png"),
    };
  },
  created() {
    this.text = this.cultureList[this.Index];
    this.textItem = this.honorList[this.IndexItem];
    console.log(this.textItem);
  },
  computed: {
    honor() {
      return this.honorList[this.honorActive];
    },
    honorNext() {
      let honorActive = this.honorActive;
      let len = this.honorList.length;
      let index = honorActive >= len - 1 ? 0 : honorActive;
      return this.honorList[index];
    },
  },
  mounted() {
    this.$nextTick(() => {
      let wow = new WOW.WOW({
        boxClass: "wow",
        animateClass: "animated",
        offset: 0,
        mobile: true,
        live: false,
      });
      wow.init();
    });
  },
  methods: {
    onMouseover(index) {
      this.active = index;
    },
    addEvent() {
      // console.log('执行')
      // if (this.histroyActive === this.histroyList.length - 1) {
      //   this.histroyActive = 0
      // } else {
      //   this.histroyActive++;
      // }
      // this.$refs.carousel.setActiveItem(this.histroyActive);
      this.$refs.carousel.prev();
      this.histroyActive = this.$refs.carousel.activeIndex;
      // console.log(this.histroyActive)
    },
    cutEvent() {
      // console.log('执行')
      // if (this.histroyActive === 0) {
      //   this.histroyActive = this.histroyList.length - 1;
      // } else {
      //   this.histroyActive--;
      // }
      // this.$refs.carousel.setActiveItem(this.histroyActive);
      // console.log(this.histroyActive);
      this.$refs.carousel.next();
      this.histroyActive = this.$refs.carousel.activeIndex;
    },
    cutEvent1() {
      this.cultureBtnActive = "next";
      if (this.Index === this.cultureList.length - 1) {
        this.Index = 0;
      } else {
        this.Index++;
      }
      this.text = this.cultureList[this.Index];
    },
    addEvent1() {
      this.cultureBtnActive = "prev";
      if (this.Index === 0) {
        this.Index = this.cultureList.length - 1;
      } else {
        this.Index--;
      }
      this.text = this.cultureList[this.Index];
    },
    handleHonorSwiper(currentInfo) {
      // console.log(currentInfo)
      // console.log(index)
      this.honorInfo = currentInfo;
      // console.log('当前滚动到了第'+index+'张')
    },
    handleClick(index) {
      this.currentIndex = index;
      this.$refs.carousel.setActiveItem(index);
    },

    onTabClick(index) {
      this.IndexItem = index;
    },
    onCultureClick(index) {
      this.cultureIndex = index;
    },
    onYearClick_h(index) {
      //年份
      this.sm_histroyActive = index;
      this.$refs.carousel_h.setActiveItem(index);
    },
    onYearClick(index) {
      //年份
      this.histroyActive = index;
      this.$refs.carousel.setActiveItem(index);
    },
    onHonorNext(index) {
      this.honorBtnActive = "next";
      this.$refs["mySwiper" + index][0].mySwiper.slideNext();
    },
    onHonorPrev(index) {
      this.honorBtnActive = "prev";
      this.$refs["mySwiper" + index][0].mySwiper.slidePrev();
    },
  },
};
</script>
<style lang="less" scoped>
@width: 50px;
// .imgList {
//   /deep/ .el-image {
//     height: 400px !important;
//     width: 100%;
//   }
// }

/deep/ .el-carousel__container {
  height: 100%;
}

.components-about-hdAbout {
  h1 {
    font-weight: bold;
  }

  .intro {
    width: 100%;
    padding: 0.25rem 1.1rem /* 80/80 */;
    box-sizing: border-box;
    .imageItem {
      height: 100%;
      display: flex;
      flex: 1;
      justify-content: flex-start;
      align-items: flex-start;
      img {
        // width: 22.9167vw;
        // height: 25.7813vw;

        height: 25.7813vw;
        width: 27.7604vw;
      }
    }
    .imageSmall {
      height: 100%;
      display: flex;
      flex: 1;
      justify-content: flex-start;
      align-items: flex-start;
      .img {
        width: 22.9167vw;
        height: 25.7813vw;

        // height:25.7813vw;
        // width:27.7604vw;
      }
    }

    .companyIntro {
      .content {
        flex: 2;
        width: 100%;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        text-align: left;
        h1 {
          font-size: 0.45rem;
          color: #333;
        }
        .des {
          margin-top: 1.2vw;
          margin-bottom: 2vw;
          p {
            font-size: 0.225rem;
            text-align: left;
            line-height: 0.5rem /* 40/80 */ /* 40/80 */;
            // padding-left: 0.125rem /* 10/80 */;
          }
        }

        .numList {
          display: flex;
          justify-content: space-between;

          .numItem {
            text-align: left;

            .nums {
              font-size: 0.5625rem;
              color: #1f63d2;
              font-weight: bold;
              padding: 0px 0.4375rem;
              // text-align: center;
              span {
                font-size: 0.225rem;
              }
            }
            .title {
              padding-top: 0.25rem;
              font-size: 0.225rem;
              // text-align: left;
            }
          }
        }
      }
    }
  }
  .introSmall {
    des {
      font-size: 14px;
      text-align: left;
      margin: 25px 0 25px 0;
    }
  }
  .cultureColor {
    height: 7.8125rem;
    margin: 1.1125rem;
    background: url("../../assets/images/other/enterprise culture.png") center
      no-repeat;
    background-size: cover;
  }
  .culture {
    // padding: 0.25rem 1.1rem;
    .companyCulture {
      padding: 0.625rem 0;
      font-size: 0.45rem;
      color: #333;
    }
    .culture-wrapper {
      // padding-bottom: 80px;
      .wrapper-left {
        width: 37.5%;
        position: relative;
        .opt-btns {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          height: 100%;

          .el-button {
            position: absolute;
            left: 1.2125rem;
            width: @width;
            height: @width;
            font-size: 16px;
            border-radius: 50%;
            padding: 0;
            transition: all 0.5s ease;
            //           &.active {
            //   background-color: #1f63d2;
            //   color: #fff;
            //   border-color: #1f63d2;
            // }

            &:first-child {
              top: 0;
            }

            &:last-child {
              bottom: 0.5rem;
            }
          }
          .cultureBtnActive {
            background-color: #1f63d2 !important;
            color: #fff !important;
            border-color: #1f63d2 !important;
          }
        }
        .culture-content {
          .padding_left {
            padding-left: 1.25rem;
            &.en {
              margin-bottom: 0.1875rem;
              font-size: 0.225rem;
              font-weight: 500;
              color: #666666;
            }
            &.desc {
              margin-top: 0.4375rem;
              font-size: 0.225rem;
              font-weight: 500;
              color: #666666;
            }
          }
          padding: 1.5rem 0 1.9rem;
          text-align: left;
          font-size: 0.25rem;
          .title {
            border-bottom: 1px solid #e6eaf0;
            color: #296ad6;
            font-size: 0.3625rem;
            position: relative;
            font-weight: 800;
            // padding-left: 1.25rem;
            i {
              width: 8px;
              height: 8px;
              border-radius: 100%;
              display: block;
              position: absolute;
              z-index: 3;
              bottom: 0;
              right: 0;
              transform: translate(-50%, 0);
              transition: all 0.5s ease 0s;

              span {
                content: "";
                background-color: #ffe562;
                border-radius: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                animation: wave 2s linear 0s infinite;
                z-index: -1;
              }

              &:before {
                content: "";
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background-color: #fff;
                z-index: 1;
                border-radius: 100%;
              }
            }

            &:after {
              content: "";
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              background-color: #ffe460;
              border-radius: 100%;
              visibility: hidden;
              opacity: 0;
              transition: all 0.5s ease 0s;
            }
          }
        }
      }
    }
    .smallScreen {
      display: flex;
      justify-content: center;
      align-items: center;

      .icon_img {
        padding: 10px;
        background-color: #fff;
        border-radius: 50%;
        cursor: pointer;
        transform: rotate(-90deg);
        margin: 0 10px;

        &:hover {
          box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
        }
      }

      .title {
        color: #296ad6;
        padding: 0 10px;
      }
    }

    .wrapper-right {
      width: 100%;
      display: flex;
      align-items: center;
      .circle-left {
        margin-right: -2.2625rem;
      }

      .circle {
        position: relative;

        width: 4.9rem;
        height: 4.9rem;

        background-color: #1f63d2;
        border-radius: 50%;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;

        .circle1 {
          position: absolute;
          top: 0.225rem;
          left: 0.225rem;
          width: 4.45rem;
          height: 4.45rem;
          border: 2px dashed #dde0e3;
          border-radius: 50%;
          -moz-border-radius: 50%;
          -webkit-border-radius: 50%;

          .circle2 {
            // display: none;
            position: absolute;
            top: 0.1875rem;
            left: 0.1875rem;
            width: 4.025rem;
            height: 4.025rem;
            background-color: #fff;
            border-radius: 50%;
            -moz-border-radius: 50%;
            -webkit-border-radius: 50%;
            img {
              // width: 240px;
              // height: 205px;
              // margin-top: 20px;
              width: 3.7rem;
              height: 3.1rem;
              margin-top: 35px;
              background-color: transparent;
            }
          }
        }
      }

      .cultureList {
        width: 6.9rem;
        height: 4.9rem;

        li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          height: 33.3%;
          padding: 0 0.5rem 0 3.125rem;
          .el-image {
            height: 1.125rem;
            width: 1.25rem;
          }

          &.active {
            background: rgb(255, 255, 255);
            border-radius: 0 60px 60px 0;
          }

          span {
            display: inline-block;
            // margin-left: @width;
            color: #296ad6;
            font-size: 0.275rem;
            margin-left: 0.5rem;
            margin-right: auto;
          }
        }
      }
    }
  }
  .smallItem {
    display: flex;
    align-items: center;
    margin-top: 20px;

    .el-col {
      border-radius: 5px;
      text-align: center;
      height: 150px;
      padding-top: 5px;
      text-align: center;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      flex-direction: column;
      cursor: pointer;
      overflow: hidden;
      position: relative;
      transition: all 0.5s ease;
      transform: translate3d(0, 0, 0);
      position: relative;

      & .el-image {
        width: 80px;
        height: 80px;
        transition: all 0.5s ease;
      }

      &:after,
      &:before {
        content: "";
        width: 100%;
        left: 0;
        top: 0;
        height: 100%;
        z-index: 0;
        position: absolute;
        transition: all 0.5s ease;
        opacity: 0;
      }

      &:before {
        background: url(../../assets/images/pro-bgimg3.png) no-repeat center
          top/100%;
        transform: translate(10px, 10px);
      }

      &:after {
        background: url(../../assets/images/pro-bgimg2.png) no-repeat center
          top/100%;
        transform: translate(-10px, 10px);
      }

      i {
        width: 100%;
        left: 0;
        top: 0;
        height: 100%;
        z-index: 0;
        position: absolute;
        transition: all 0.5s ease;
        opacity: 0;
        background: url(../../assets/images/pro-bgimg1.png) no-repeat center
          top/100%;
        transform: translateY(-10px);
      }

      &.active {
        background-color: #2063d2;
        color: #fff;

        &:before,
        &:after {
          transform: translate(0, 0);
          opacity: 1;
        }

        & .el-image {
          transform: translateY(-10px);
        }

        i {
          transform: translateY(0);
          opacity: 1;
        }
      }
    }
  }

  .history {
    // margin: @width;
    margin: 1.1125rem;

    h1 {
      font-size: 0.5rem;
    }
    .opt-btns {
      left: 50%;
      width: 100%;
      transform: translate(-50%, -50%);

      .el-button {
        left: 50%;
        transform: translateX(-50%);
        &.active {
          background-color: #1f63d2;
          color: #fff;
          border-color: #1f63d2;
        }
      }
    }
    // .opt-btns {
    //   .el-button {
    //     width: 40px;
    //     height: 40px;
    //     line-height: 38px;
    //     font-size: 16px;
    //   }
    // }

    .histroy-wrapper {
      display: flex;
      justify-content: space-between;

      .history-left {
        width: 11%;
        display: flex;
        flex-direction: column;
        //border: 1px solid salmon;
        border-left: 0.05rem dotted #ccc;
        position: relative;

        .btn_item {
          text-align: left;
          padding-left: 0.25rem;
          .el-button:focus {
            background-color: #1f63d2;
            color: #fff;
            border-color: #1f63d2;
          }
        }

        .prev-btn,
        .next-btn {
          border-radius: 50%;
          padding: 0.15rem;
          // box-shadow: inset 0 0.3rem 0.2rem rgba(255, 255, 255, 0.2),
          //   inset 0 -0.3rem 0.2rem #f2f6fc, 0 0.2rem 0.4rem rgba(0, 0, 0, 0.2);
        }

        .year-wrapper {
          width: 100%;
        }

        ul {
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          flex-flow: column;
          width: 100%;
          height: 100%;

          li {
            display: flex;
            padding: 0.234rem 0.25rem;
            position: relative;
            font-size: 0.2rem;
            width: 100%;
            cursor: pointer;
            text-align: left;

            &.active {
              color: #1f63d2;
              font-size: 0.3125rem;
              font-weight: bold;

              &::before {
                content: "";
                width: @width / 5;
                height: @width / 5;
                border-radius: 50%;
                border: @width / 20 solid #1f63d2;
                background: #fff;
                position: absolute;
                top: 50%;
                left: -10px;
                transform: translateY(-50%);
              }
            }
          }
        }
      }
      .histroy-wrapper-col {
        position: relative;
        .carousel_box {
          .el-image {
            width: 100%;
            height: 6.675rem;
            display: flex;
            align-items: center;
            /deep/ .el-image__inner {
              height: auto;
            }
          }
          .el-col:last-child {
            position: relative;
            display: flex;
            align-items: center;
            background: url("../../assets/images/other/course.jpg") no-repeat
              center;
            background-size: 100% 100%;
          }
          .contentList {
            text-align: left;
            padding-left: 1.3625rem;
            font-size: 0.25rem;
            li {
              margin: 15px 0 0.5rem 0;
              .month {
                color: cornflowerblue !important;
                display: inline-block;
                //margin-right: @width / 2;
                font-weight: bold;
                margin-right: 0.275rem;
              }
            }
          }
          p {
            // align-self: flex-end;
            // height: 33px;
            // font-size: 59px;
            // font-weight: 700;
            // line-height: 55px;
            // overflow: hidden;
            // position: absolute;
            // text-align: center;
            // color: #EFEFEF;
            //   margin-left: 100px;

            // height: 37px;
            //     font-size: 75px;

            //     line-height: 58px;
            //     overflow: hidden;
            //     position: absolute;
            //     left: 110px;
            //     bottom: 0;
            //     z-index: 1;
            //         color: #EFEFEF;

            height: 0.6625rem;
            font-size: 0.9375rem;
            // line-height: .725rem;
            overflow: hidden;
            position: absolute;
            left: 1.375rem;
            bottom: 0;
            z-index: 1;
            color: #efefef;
          }
        }
      }
    }
  }
  .history2 {
    h1 {
      font-size: 0.45rem;
      color: #333;
    }
    // height: 800px;
    width: 100%;
    // justify-content: center;
    //  text-align: center;

    .elCarousel {
      &::after {
        content: "";
        display: block;
        clear: both;
        height: 0;
        visibility: hidden;
      }
      .carousel_box {
        &::after {
          content: "";
          display: block;
          clear: both;
          height: 0;
          visibility: hidden;
        }
        .imgItem {
          height: 400px;
          width: 100%;
        }
      }
    }
  }
  .honor {
    margin: 0 1.1125rem;
    h1 {
      font-size: 0.5rem;
    }
    .tabs-content {
      display: flex;
      justify-content: center;
      align-items: center;
      //width: 30%;
      margin: 0 auto;
    }

    .tabs-item {
      color: #636363;
      cursor: pointer;
      border: 1px solid #dddddd;
      padding: 0.0625rem;
      display: flex;

      span {
        padding: 0 0.3125rem;
        font-size: 0.2rem;
      }

      &:first-child {
        border-radius: 20px 0 0 20px;
        border-right-color: transparent;
      }

      &:last-child {
        border-radius: 0 20px 20px 0;
        border-left-color: transparent;
      }

      &.active {
        border-color: #2e6ed5;
        background: #f1f8ff;
      }
    }

    .honor-wrapper {
      display: flex;
      justify-content: center;
      // width: 80%;
      margin: 52px auto 0px;
    }
    .honor_info li {
      padding: 10px 0;
    }
    .sm-honor-image {
      max-height: 10rem;
      max-width: 100%;
      margin: 0 auto;
    }
    .honor-left {
      position: relative;
      display: flex;
      flex-direction: column;
      .opt-btns {
        flex: 1;
        text-align: left;
        margin-top: 1.35rem /* 400/80 */;
        //top: auto;
        //bottom: 0;
        //transform: none;
        //height: auto;
        .el-button {
          margin-right: 20px;
          &:first-child {
            top: auto;
            bottom: 0;
            left: 0;
          }

          &:last-child {
            left: 100px;
          }
        }
        .honorBtnActive {
          background-color: #1f63d2 !important;
          color: #fff !important;
          border-color: #1f63d2 !important;
        }
      }
      ul {
        flex: 4;
        li {
          color: #9c9c9c;
          text-align: left;
        }

        .year {
          font-size: 0.25rem /* 20/80 */;
          font-weight: bold;
          margin-bottom: 0.5625rem;

          span {
            font-size: 0.75rem;
            color: #1f63d2;
            display: inline-block;
            margin-right: 0.125rem /* 10/80 */;
          }
          .yearColor {
            font-size: 0.5rem;
            color: #999999;
          }
        }

        .title {
          font-size: 0.3rem;
          color: #333;
          margin-bottom: @width / 5;
          position: relative;
          margin-right: 0.1rem;
          display: flex;
          align-items: center;
          .line {
            margin-left: 0.625rem /* 50/80 */;
            display: inline-block;
            height: 2px;
            width: 1rem /* 80/80 */;
            height: 1px;
            background: #000000;
            opacity: 0.3;
          }
        }

        .des {
          font-size: 0.2rem;
        }
      }
    }
    .honor-right {
      display: flex;
      align-items: flex-start;
      .active {
        height: 8.125rem /* 650/80 */;
        width: 8.75rem;
        // background: url(../../assets/images/honor/honor1/honor3.png) center
        //   no-repeat;
        // background-size: 100% 100%;
        position: relative;
        .backPic {
          position: absolute;
          bottom: 1.375rem /* 110/80 */;
          width: 4.375rem /* 350/80 */;
          left: 50%;
          transform: translateX(-50%);
          img {
            width: 100%;
            height: 100%;
            box-shadow: 0 3px 6px 3px rgba(0, 0, 0, 0.1);
            /*display: none;*/
          }
        }
        .backTwoOrThree {
          width: 6.25rem /* 500/80 */ !important;
        }
      }

      li {
        //max-width: 200px;
        //max-height: 300px;
        //margin-right: 5%;
      }

      .next {
        opacity: 0.3;
        transition: all 0.5s ease;
        height: 100%;
        display: flex;
        align-items: center;
        margin-left: 20px;
        img {
          height: 5rem /* 400/80 */;
          width: 5rem /* 400/80 */;
        }
      }
    }
  }
  .cultureSmall {
    h1 {
      margin-top: 1.5rem;
      // font-size: 0.5rem;
      font-size: 0.45rem;
      color: #333;
    }
  }
  .history {
    h1 {
      margin-bottom: 0.5rem;
      font-size: 0.45rem;
      color: #333;
    }
  }
  .honor {
    h1 {
      margin-bottom: 0.5rem;
      font-size: 0.45rem;
      color: #333;
    }
  }
}

/deep/ .history_tabList {
  .tabLIst {
    flex-wrap: wrap;
    justify-content: space-around;
    margin: auto;
    border-bottom: 4px dotted #ccc;
    font-size: 0.2rem;
  }

  .item {
    padding: 5px 10px;
    margin-right: 10px;
    margin-top: 20px;
    border-radius: 10px;
    background-color: #eee;
    position: relative;
  }

  .current {
    background-color: #2b6bd5;
    color: #fff;

    &::before {
      content: "";
      width: @width / 5;
      height: @width / 5;
      border-radius: 50%;
      border: @width / 20 solid #1f63d2;
      background: #fff;
      position: absolute;
      bottom: -18px;
      left: 50%;
      margin-left: -7.5px;
    }
  }
}

@media only screen and (max-width: 1170px) {
}
@media only screen and (max-width: 992px) {
  .imageSmall {
    justify-content: center !important;
  }
  .intro {
    margin-top: -35px;
  }
  .content {
    h1 {
      text-align: center;
    }
    .des p {
      line-height: 0.7rem !important;
    }
  }
}
@media only screen and (max-width: 991px) {
  .components-about-hdAbout {
    .head-title {
      font-size: 0.35rem;
    }
    .honor_info li {
      // font-size: 0.35rem;
      font-size: 0.225rem;
    }
    .contentList li {
      font-size: 0.225rem !important;
    }
    .imageSmall {
      img {
        height: 10rem !important;
        width: 10rem !important;
      }
    }
    .intro {
      .companyIntro {
        .content {
          .des,
          h1 {
            margin-top: 0.5rem !important;
          }
        }
      }
    }
    .history2 {
      h1 {
        font-size: 0.5rem;
        margin-top: 1.5rem;
      }
      .elCarousel {
        display: flex;
        justify-content: center;
        &::after {
          content: "";
          display: block;
          clear: both;
          height: 0;
          visibility: hidden;
        }
        /deep/ .el-carousel__container {
          width: 80vw;
          height: 45vw;
        }
      }
      .contentList {
        li {
          margin-top: 0.2rem;
          font-size: 0.375rem;
        }
      }
    }
    .honor {
      h1 {
        margin-top: 1.5rem;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .sm-honor-image {
    margin: 0 0.25rem /* 20/80 */;
    margin: 0 auto !important;
  }
  .intro {
    padding: 0.0125rem !important;
  }
  //   .imageItem .el-image{
  //   height: 4.9125rem!important;
  //     width: 4.0875rem!important;
  // }
  // .content h1{
  //   font-size: .25rem!important;
  // }
  //  .content .des p{
  //    font-size: 0.195rem!important;
  //  }
}
@media only screen and (max-width: 680px) {
  .tabs-item span {
    padding: 0 0.125rem !important;
  }
}
@media only screen and (max-width: 480px) {
  .components-about-hdAbout .honor {
    margin: 0 !important;
  }
  .components-about-hdAbout {
    .imageSmall {
      img {
        height: 6rem !important;
      }
    }
  }
}
@keyframes wave {
  0% {
    width: 0;
    height: 0;
    opacity: 0;
  }
  3% {
    width: 0;
    height: 0;
    opacity: 1;
  }
  40% {
    width: 20px;
    height: 20px;
    opacity: 1;
  }
  80% {
    width: 40px;
    height: 40px;
    opacity: 0;
  }
  100% {
    width: 0px;
    height: 0px;
    opacity: 0;
  }
}

.animation {
  -webkit-animation: fadeInRight 1s 0.2s ease both;
  -moz-animation: fadeInRight 1s 0.2s ease both;
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes fadeInRight {
  0% {
    opacity: 0;
    -moz-transform: translateX(20px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}

@-webkit-keyframes SpinCircle {
  to {
    transform: rotate(0deg);
  }
  from {
    transform: rotate(360deg);
  }
}
@-moz-keyframes SpinCircle {
  to {
    transform: rotate(0deg);
  }
  from {
    transform: rotate(360deg);
  }
}
.SmallCircle_box {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border-radius: 50%;
  /*animation:动画名称 持续时间 运动曲线 何时开始 播放次数 是否反向 动画起始或结束状态;*/
  -webkit-animation: SpinCircle 6s linear infinite;
  -moz-animation: SpinCircle 6s linear infinite;
  .SmallCircle {
    /*border-bottom: 1px solid #e6eaf0;*/
    color: #296ad6;
    font-size: 0.375rem;
    position: relative;
    /*padding-left: 1.25rem;*/
    i {
      width: 8px;
      height: 8px;
      border-radius: 100%;
      display: block;
      position: absolute;
      z-index: 3;
      bottom: -0.0375rem /* -3/80 */;
      right: 0;
      transform: translate(-50%, 0);
      transition: all 0.5s ease 0s;

      span {
        content: "";
        background-color: #ffe562;
        border-radius: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        animation: wave 2s linear 0s infinite;
        z-index: -1;
      }

      &:before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #fff;
        z-index: 1;
        border-radius: 100%;
      }
    }

    &:after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #ffe460;
      border-radius: 100%;
      visibility: hidden;
      opacity: 0;
      transition: all 0.5s ease 0s;
    }
  }
}
</style>
