<template>
  <div style="position: relative" class="content">
    <el-row
      class="tabList"
      v-for="(tabListItem, tabListIndex) in tabList"
      :key="tabListIndex"
    >
      <el-col v-for="(item, index) in tabListItem" :key="index">
        <div
          ref="item"
          class="item"
          :class="{ current: currentName === item }"
          @click="goto(index, $event.target, item)"
        >
          <i class="el-icon-location-outline" v-if="currentName === item"></i>
          {{ item }}
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "index.vue",
  props: {
    tabList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      currentName: "江苏",
    };
  },
  methods: {
    goto(index, dom, item) {
      this.currentName = item;
      this.$emit("change", index, item);
    },
  },
};
</script>

<style scoped lang="less">
.tabList {
  margin-top: .6625rem;
  &:first-child {
    margin-top: 0;
  }
  .el-col {
    display: flex;
    align-items: center;
    .item {
      background-color: #F2F8FF;
      line-height: 0.4375rem;
      // height: 0.4375rem;
      width: 0.875rem;
      cursor: pointer;
      min-width: 0.5rem;
      border-radius: 0.25rem;
      font-size: 0.225rem;
      span {
        line-height: 0.4375rem;
      }
      &.current {
        width: 1.25rem;
        background: #5C8AD4 !important;
        color: #fff;
      }
    }
  }
  &:last-child .item {
    background-color: #ffe0001c;
    // margin-bottom: 0.2rem;
      margin-bottom: .725rem;
     &.current {
        width: 1.25rem;
        background:#EEDEAD !important;
        color: #fff;
      }

  }
}

@media only screen and (min-width: 1199px){
  .tabList {
    .el-col {
      width: 10%;
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tabList {
    .el-col {
      width: 10%;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tabList {
    .el-col {
      width: 10%;
      .item {
        line-height: 0.5rem;
        height: 0.5rem;
        width: 1rem;
        &.current {
          width: 1.5rem;
        }
      }
    }
  }
}

@media only screen and (min-width: 600px) and (max-width: 767px) {
  .tabList {
    margin-top: 0;
    .el-col {
      width: 16%;
      margin-top: 2%;
    }
  }
}

@media only screen and (max-width: 599px) {
  .tabList {
    margin-top: 0;
    .el-col {
      width: 20%;
      margin-top: 2%;
    }
  }
}
</style>
