<template>
  <div style="position: relative">
<!--    <div class="aside_slide" :style="styleList"></div>-->
    <div class="tabLIst">
      <div ref="item" class="item" v-for="(item,index) in tabList" :key="index" @click="goto(index,$event.target,item)"
           :class="{'current':currentIndex===index}">
        <span>{{ item }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index.vue",
  props: {
    tabList: {
      type: Array,
      default() {
        return ['账单', '充值', '营销', '财务流水', '流水']
      }
    },
      InitialIndex:{
          type:Number,
          default:0
      }
  },
  data() {
    return {
      currentIndex: this.InitialIndex,
      styleList: {}
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      const cur = document.querySelector('.current span')
      this.goto(this.currentIndex, cur,this.tabList[this.currentIndex])
    },
    goto(index, dom,item) {
      this.currentIndex = index
      this.styleList = {
        left: dom.offsetLeft + 'px',
        width: dom.offsetWidth + 'px'
      }
      this.$emit('change',index,item)
    }
  }
}
</script>

<style scoped>
.tabLIst {
  display: flex;
  padding: 10px;
}

.aside_slide {
  width: 30px;
  height: 1px;
  background-color: red;
  transition: all .5s;
  position: absolute;
  left: 19px;
  bottom: 0;
}

.item {
  cursor: pointer;
  padding:0 10px;
}

.current {
  color: dodgerblue;
}
</style>
