<template>
  <div class="swiper-container">
    <div class="swiper-wrapper">
      <div class="swiper-slide " v-for="(key,value) in imgList" :key="value">
        <div class="swiper-slide-style" >
           <img :src="key.imgSrc">
          <img :src="key.imgSrc" v-if="value==0||value==(imgList.length-1)">
        </div>
      </div>
    </div>
    <!-- Add Pagination 指示器 如果没有配置就不会显示-->
    <div class="swiper-pagination"></div>
  </div>
</template>

<script>
let time = null
import Swiper from "swiper";

export default {
  props: ['imgList','index'],
  data() {
    return {
      mySwiper: {},//存储函数的new 出来的实例 里面包含方法等等
    }
  },
  mounted() {
    // console.log('mounted')
    this.$nextTick(()=>{
      this.InitHonorSwiper()
    })
  },
  beforeDestroy() {
    this.mySwiper.destroy(false)
    // this.mySwiper[this.index].destroy(false);//销毁轮播图
    clearTimeout(time)
  },
  methods: {
    //资质荣誉 大屏下显示的轮播
    InitHonorSwiper() {
      let _this = this
      this.mySwiper = new Swiper('.swiper-container', {
        spaceBetween: 40,//间距
        slidesPerView: 2,//展示多少个
        watchActiveIndex: true,//是否设置激活的
        initialSlide:0,
        loop: true,//是否循环
        slideToClickedSlide: true, //幻灯片是否可点击
        // autoplay: {
        //   delay: 2000,//2秒切换一次
        //    disableOnInteraction: false//触碰后是否停止轮播
        // },//是否自动播放
        on: {//监听函数
          slideChange: function() {//轮播图滚动后发生改变的时候可以获取索引  这里的this是在Swiper构造器中 所以指向Swiper不是vue
            if (time) clearTimeout(time)//如果当前定时器正在执行 则清除定时器 因为上次还在执行定时器是异步执行的，不会覆盖 所以要清除上一次定时器
            time = setTimeout(() => {
              //_this.imgList[this.realIndex]当前的信息
              _this.$emit('change', _this.imgList[this.realIndex], this.realIndex)
              // _this.honorInfo=_this.honorList[_this.IndexItem].pic[this.realIndex]
            }, 400)
          },
        },
        // pagination: {//绑定指示器
        //   el: '.swiper-pagination',
        //   clickable: true,//指示器是否可点击
        // },
      });
    },
    //控制轮播图切换到第一张
    setSwiperFirst(){
      console.log(this.mySwiper)
      this.mySwiper[this.index].slideTo(0)
    }
  }
}
</script>

<style scoped lang="less">
  .swiper-container {
    width: 100%;
    height: 8.125rem  /* 650/80 */;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
  }
.swiper-slide-style{
  width: 100%;
  height: 100%;
  display: table-cell;
  text-align: center;
  vertical-align: bottom;
  img{
    position:absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%,0%);
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
  }
}

  .swiper-slide {
    position: relative;
    text-align: center;
    font-size: 18px;
    // background: #9e9e9f;
    height: 3.375rem;
    /*height: 8.125rem !* 650/80 *!;*/
    width: 8.75rem;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    opacity: 0.1;
    transition: all 3s;
    /*transition: 300ms;*/
    /*-webkit-transform: scale(0.8);*/
    /*-moz-transform: scale(0.8);*/
    /*-ms-transform: scale(0.8);*/
    /*-o-transform: scale(0.8);*/
    /*transform: scale(0.8);*/
  }

  .swiper-slide-active {
    // background-color: #fff;
    height: 8.125rem !important/* 650/80 */;
    /*top: 0;*/
    opacity: 1;
    /*-webkit-transform: scale(1);*/
    /*-moz-transform: scale(1);*/
    /*-ms-transform: scale(1);*/
    /*-o-transform: scale(1);*/
    /*transform: scale(1);*/
  }
</style>
