<template>
  <div class="components-about-hdJoin">
    <el-row class="Process">
      <h1>招聘流程</h1>
      <el-row class="zhaopinList">
        <div
          class="zhaopinItem wow fadeInUp "
          :class="{ active: index == zhaopinActive, arrow: item.arrow }"
          v-for="(item, index) in zhaopinList"
          :key="index"
          @click="onZhaopinClick(index)"
          data-wow-duration="0.8s"
          :data-wow-delay="0.2 * index + 's'"
        >
          <el-image :src="item.src" lazy />
          <p>{{ item.title }}</p>
        </div>
      </el-row>
      <ul
        class="contentList wow fadeInUp"
        data-wow-duration="0.8s"
        data-wow-delay="0.2s"
      >
        <li
          v-for="(item, index) in infos"
          :key="index"
          v-show="index == zhaopinActive"
        >
          <h1>{{ item.title }}</h1>
          <p>
            <span>{{ item.value.split("：")[0] }}</span
            ><span>{{ item.value.includes("：") ? "：" : "" }}</span
            ><span class="buleText">{{ item.value.split("：")[1] }}</span>
          </p>
          <p>{{ item.value1 }}</p>
        </li>
      </ul>
    </el-row>
    <el-row
      class="recruiTment wow fadeInUp "
      data-wow-duration="0.8s"
      data-wow-delay="0.2s"
    >
      <h1>招贤纳士</h1>
      <el-form :inline="true" :model="searchFrom">
        <el-form-item class="zhaopin-from">
          <el-radio-group v-model="searchFrom.v_radio" class="radioClass">
            <el-radio-button label="0">社会招聘</el-radio-button>
            <el-radio-button label="1">校园招聘</el-radio-button>
            <!-- <el-button type="primary" label="0"  round>社会招聘</el-button>
            <el-button type="primary" label="1" round>校园招聘</el-button> -->
          </el-radio-group>
        </el-form-item>
        <!-- <div v-show="searchFrom.v_radio==='0'"> -->
        <el-form-item>
          <el-input
            placeholder="岗位名称"
            v-model="searchFrom.postName"
            @change="PostNameInput"
            clearable
            filterable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="searchFrom.professionType"
            placeholder="职能类别"
            clearable
            filterable
            @change="ProfessionChange"
          >
            <el-option
              v-for="(item, index) in profession"
              :key="index"
              :label="item.type"
              :value="item.type"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="searchFrom.depart"
            placeholder="所属部门"
            clearable
            filterable
            @change="departChange"
          >
            <el-option
              v-for="item in depart"
              :key="item.id"
              :label="item.depart"
              :value="item.depart"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="searchFrom.address"
            placeholder="工作地点"
            clearable
            filterable
          >
            <el-option
              v-for="(item, index) in address"
              :key="index"
              :label="item.place"
              :value="item.place"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="searchBtn-container">
          <div @click="btn" class="search-btn-box">
            <img v-lazy="searchBtn" />
          </div>
        </el-form-item>
        <!-- </div> -->
      </el-form>
      <el-row class="hd-table">
        <ul class="hd-tableHead">
          <li class="hd-tr">
            <div class="tr-body">
              <div>岗位名称</div>
              <div>所属部门</div>
              <div>工作地点</div>
              <div>薪资范围</div>
              <div class="hidden-xs-only" style="width: 40%"></div>
            </div>
          </li>
        </ul>
        <transition name="el-fade-in-linear">
          <ul
            class="hd-tableBody el-fade-in-linear"
            v-show="searchFrom.v_radio == 0"
          >
            <li
              class="hd-tr"
              v-for="(item, index) in source.tableList"
              :key="index"
            >
              <!--:class="{'activeItem': boxShow === index}" activeItem class类名 如果activeItem 等于当前循环中的index 就为true 否则为false
          相当于 :class="{'activeItem': true}" 显示   :class="{'activeItem': false}" 不显示-->
              <!-- index 是当前循环中的当前index 是动态的  boxShow你点击的时候传递index给他赋值  然后两个相等就会为true-->
              <div class="tr-body" :class="{ activeItem: boxShow === index }">
                <!-- 很明显可以看到是tr-body  =''的花这里就不成立 也就是 :class="{'activeItem': false}" 不显示-->
                <div class="btns-sm hidden-sm-and-up">
                  <i
                    v-if="source.expandIndex == index"
                    class="el-icon-caret-bottom"
                    @click="onCollapse(index)"
                  ></i>
                  <i
                    v-else
                    class="el-icon-caret-right"
                    @click="onExpand(index)"
                  ></i>
                </div>
                <div class="profession">{{ item.name }}</div>
                <div>{{ item.department }}</div>
                <div>{{ item.workplace }}</div>
                <div>{{ item.salaryRange }}</div>
                <div class="btns hidden-xs-only">
                  <span
                    v-if="source.expandIndex == index"
                    class="collapseBtn"
                    @click="onCollapse(index)"
                    >收起</span
                  >
                  <span v-else @click="onExpand(index)">展开</span>
                </div>
              </div>
              <div class="other" v-if="source.expandIndex == index">
                <div class="duty">
                  <div class="fontSize">岗位职责：</div>
                  <ul>
                    <li
                      v-for="(duty, dutyIndex) in item.detail.duty"
                      :key="dutyIndex"
                    >
                      {{ dutyIndex + 1 }}、{{ duty }}
                    </li>
                  </ul>
                </div>
                <div class="claim">
                  <div class="fontSize">任职要求：</div>
                  <ul>
                    <li
                      v-for="(claim, claimIndex) in item.detail.claim"
                      :key="claimIndex"
                    >
                      {{ claimIndex + 1 }}、{{ claim }}
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </transition>
        <transition name="el-fade-in-linear">
          <ul class="hd-tableBody infoms " v-show="searchFrom.v_radio == 1">
            <li>暂无招聘信息</li>
          </ul>
        </transition>
      </el-row>
    </el-row>
    <el-row
      class="lifeContent wow fadeInLeft "
      data-wow-duration="0.8s"
      data-wow-delay="0.2s"
    >
      <h1>航动生活</h1>
      <el-row class="hidden-sm-and-down">
        <el-col :md="24">
          <el-carousel
            ref="life"
            trigger="click"
            indicator-position="none"
            arrow="never"
            class="life-wrapper"
            :autoplay="false"
          >
            <el-carousel-item
              class="lifeList"
              v-for="(item, index) in source.lifeList"
              :key="index"
            >
              <div
                class="lifeItem"
                v-for="(pic, picIndex) in item"
                :key="picIndex"
              >
                <img v-lazy="pic" />
              </div>
            </el-carousel-item>
          </el-carousel>
        </el-col>
      </el-row>
      <el-row class="hidden-md-and-up picFontSize">
        <el-col :xs="24" :sm="24" :md="12">
          <el-carousel
            :interval="4000"
            type="card"
            :autoplay="false"
            height="200px"
            indicator-position="none"
          >
            <el-carousel-item
              class="lifeList"
              v-for="(item, index) in source.lifeListArray"
              :key="index"
            >
              <div
                class="lifeItem"
                v-for="(pic, picIndex) in item"
                :key="picIndex"
                style="height: 100%;width: 100%;"
              >
                <img v-lazy="pic" style="height: 100%;width: 100%;" />
              </div>
            </el-carousel-item>
          </el-carousel>
        </el-col>
      </el-row>

      <el-row class="hidden-sm-and-down item_opt">
        <el-col>
          <el-button-group class="opt-btns">
            <el-button
              :class="['prev-btn']"
              round
              icon="el-icon-arrow-left"
              @click="onLifePrev"
            ></el-button>
            <el-button
              round
              :class="['next-btn']"
              icon="el-icon-arrow-right"
              @click="onLifeNext"
            ></el-button>
          </el-button-group>
        </el-col>
      </el-row>
    </el-row>
    <pageMore :newsLis="newsLis"></pageMore>
  </div>
</template>
<script>
import WOW from "wowjs";
import img1 from "@/assets/send.png";
import img2 from "@/assets/primary.png";
import img3 from "@/assets/interview.png";
import img4 from "@/assets/offer.png";
import pageMore from "@/components/common/pageMore.vue";
export default {
  name: "components-about-hdJoin",
  components: {
    pageMore,
  },
  data() {
    return {
      newsLis: [
        {
          name: "news",
          textImg: require("@/assets/images/icon-foot.png"),
        },
      ],
      searchFrom: {
        v_radio: 0,
        postName: null,
        professionType: null,
        depart: null,
        address: null,
      },
      profession: [],
      depart: [],
      address: [],
      search: "",
      searchData: "",
      boxShow: "", //默认显示
      products: [
        //假数据
        {
          id: "1",
          name: "总经理助理",
          type: "经理",
          depart: "总经办",
          place: "上海",
        },
        {
          id: "2",
          name: "人事行政专员",
          type: "行政",
          depart: "人事行政部",
          place: "上海|分公司",
        },
        {
          id: "3",
          name: "成本会计",
          type: "财务",
          depart: "财务部",
          place: "上海|分公司",
        },
        {
          id: "4",
          name: "投标主管",
          type: "商务",
          depart: "市场商务部",
          place: "上海",
        },
        {
          id: "5",
          name: "前端开发岗位",
          type: "研发",
          depart: "上海研发部",
          place: "上海",
        },
        {
          id: "6",
          name: "后端开发岗位",
          type: "研发",
          depart: "上海研发部",
          place: "上海",
        },
        {
          id: "7",
          name: "运维工程师",
          type: "研发",
          depart: "上海研发部",
          place: "上海",
        },
        {
          id: "8",
          name: "运营经理",
          type: "研发",
          depart: "话加事业部",
          place: "根据业务城市分配",
        },
        {
          id: "9",
          name: "商务经理",
          type: "事业",
          depart: "话加事业部",
          place: "根据业务城市分配",
        },
      ],
      liftImg: "",
      textItem: "",
      lifeIndex: 0,
      searchBtn: require("@/assets/images/head-sch.png"),
      zhaopinActive: 0,
      zhaopinList: [
        {
          title: "简历投递",
          arrow: true,
          src: img1,
        },
        {
          arrow: true,
          src: img2,
          title: "简历初选",
        },
        {
          arrow: true,
          src: img3,
          title: "面试安排",
        },
        {
          src: img4,
          title: "录用通知",
        },
      ],
      infos: [
        {
          title: "简历投递",
          value: "官方邮箱：hr@npnets.com",
          value1: "(请通过航动官网或者其他招聘渠道投递简历)",
        },
        {
          title: "简历初选",
          value: "专业HR会对您的简历进行初步匹配并邀请您电话沟通",
          value1: "请在简历投递后1-3个工作日内保持电话畅通",
        },
        {
          title: "面试安排",
          value:
            "通常安排人事部门及用人部门2轮面试，并于3个工作日内告知候选人面试结果",
          value1:
            "若公司暂无在招岗位与您的条件匹配，您的简历也将纳入我司后备人才库妥善保管",
        },
        {
          title: "录用通知",
          value: "面试通过后，将有特定的人事专员与您联系提供入职指导",
          value1: "感谢您对航动的支持与信赖，也预祝您在航动切工作一切顺利",
        },
      ],
      source: {
        tableList: [
          {
            name: "总经理助理",
            workplace: "上海",
            salaryRange: "10k-15k/月",
            department: "总经办",
            profession: "经理",
            detail: {
              duty: [
                "协助总经理进行日常事务管理，负责总经理各项日常安排的准备和协调工作",
                "根据总经理要求，起草各项报告，总结，计划，决议等",
                "协助总经理完成对外联络、商务谈判、客户接待等工作，建立与维系公司重要合作伙伴的关系",
                "协助总经理与各部门之间的沟通协调 ，以及日常外联工作",
                "及时转送总经理签发下达给各部门的交办任务，并及时向总经理汇报",
                "协调跟进总经理安排的各项工作，完成上级交给的其它事务性工作",
              ],
              claim: [
                "全日制统招本科以上学历，专业不限",
                "24-35岁，形象气质佳、性格温和、稳重大方，思维活跃，办事机灵，具有良好的时间管理能力",
                "认同公司的定位、文化和发展战略",
                "有强烈的责任感、良好的职业素养和职业操守，工作认真严谨，学习能力强，具有团队合作精神及服务意识，具有极强的保密意识",
                "有较强的组织、协调、沟通及人际交往能力及具有良好书面写作及表达能力",
                "抗压能力强，执行力强，能够接受短期出差",
                "有驾照优先",
              ],
            },
          },
          {
            name: "人事行政专员",
            workplace: "上海|分公司",
            salaryRange: "6k-8k/月",
            department: "人事行政部",
            profession: "行政",
            detail: {
              duty: [
                "负责公司行政区域的环境卫生的维护，及时和保洁沟通，保持办公区域环境的良好",
                "负责公司各类行政费用的申报，费用申请报销工作",
                "负责公司每月员工考勤的汇总，保障员工考勤表无出入",
                "负责员工入离职手续的办理，保证员工入职资料准确无误完整，离职手续办理及时",
                "负责公司通讯录的及时更新，保障公司通讯录信息完整",
                "负责协助领导做公司团建事宜，例如中秋，生日会，年会、旅游等的对接，物料准备等",
                "领导安排的其他临时事情",
              ],
              claim: [
                "大专以上学历",
                "做事情要懂及时反馈，及时解决，懂得应变",
                "办公软件的良好使用（excel，word，ppt等）",
                "有2年以上行政人事专员 / 助理 或类似工作经验优先考虑",
              ],
            },
          },
          {
            name: "成本会计",
            workplace: "上海|分公司",
            salaryRange: "8k-10k/月",
            department: "财务部",
            profession: "财务",
            detail: {
              duty: [
                "统计、核对和分析来自各部门成本相关的数据，进行成本核算",
                "完善成本核算的方法，进行合理的费用归集及分配方法做好相关成本资料的整理、归档、数据库建立、查询和更新工作",
                "应付账款的维护，分析，付款审核，各项采购单价进行稽核",
                "监督各部门执行成本计划情况;进行成本管理、成本控制，实现成本管理的持续优化",
                "完成领导安排的其他事务",
              ],
              claim: [
                "会计、财务管理相关专业",
                "2年以上财务成本会计经验",
                "具有较强的逻辑思维能力，具有有关财务分析各项指标和运算知识的能力",
                "熟练使用WORD、EXCEL等office办公软件，精通EXCEL函数，熟练使用财务系统",
                "具备良好的沟通能力",
                "爱岗敬业、诚实守信、为人正直、能吃苦、有一定的抗压能力",
              ],
            },
          },
          {
            name: "招投标主管",
            workplace: "上海",
            salaryRange: "10k-15k/月",
            department: "市场商务部",
            profession: "商务",
            detail: {
              duty: [
                "招投标市场开拓工作",
                "招投标部门的综合管理工作",
                "制定和建立招投标部门工作计划和流程",
                "审核合同和招投标文件",
                "监督、指导、和控制招投标工作",
                "内外部沟通工作",
              ],
              claim: [
                "有招投标工作人脉优先考虑",
                "5年以上从事相关岗位工作经验，2年以上技术类招投标管理同岗位工作经验",
                "具有较高的谈判技巧与沟通能力、具有较强的分析能力",
                "思路清晰，独立性强，具有较强的领导及组织能力",
              ],
            },
          },
          {
            name: "前端开发岗位",
            workplace: "上海",
            salaryRange: "12k-17k/月",
            department: "上海研发部",
            profession: "研发",
            detail: {
              duty: [
                "使用HTML/CSS/JavaScript/AJAX等各种Web技术进行移动端、PC端界面开发",
                "整体页面结构及样式层结构的设计、优化",
                "两年以上专职Javascript开发经验，有websocket，jssip开发经验优先考虑",
                "熟悉react、vuejs",
                "熟练运用CSS3新特性，熟悉HTML5最新规范",
                "熟悉less/sass、es6语法",
                "熟悉常用的构建工具（包括gulp或webpack）",
                "熟悉常用的包管理器（包括bower或npm）",
              ],
              claim: [
                "本科以上学历",
                "工作仔细，认真负责，思路清晰，条理性好",
                "在团队的代码格式、结构的规定下，编写易读、易维护、高质量、高效率的代码",
                "具有良好的沟通能力、团队配合精神，对新技术敏感",
                "具有良好的语言表达能力和团队协作能力",
                "开发速度快、抗压能力强，可应对较大的工作压力",
              ],
            },
          },
          {
            name: "后端开发岗位",
            workplace: "上海",
            salaryRange: "10k-15k/月",
            department: "上海研发部",
            profession: "研发",
            detail: {
              duty: [
                "主要负责对公司开发完成的平台的开发任务",
                "次要负责提供日常技术性问题排查及性能优化",
                "参与软件系统设计，负责开发文档编写工作",
                "负责解决开发过程中的技术问题,统一代码风格、落实测试、验收代码质量",
                "熟练运用CSS3新特性，熟悉HTML5最新规范",
                "熟悉less/sass、es6语法",
                "熟悉常用的构建工具（包括gulp或webpack）",
                "熟悉常用的包管理器（包括bower或npm）",
              ],
              claim: [
                "精通JAVA，熟悉Jave多线程，WEBSOCKET等",
                "熟悉Spring/SpringMVC/MyBatis/等主流开发框架",
                "熟悉spring boot，spring cloud  微服务框架",
                "熟悉 MySQL，Oracle等主流数据库中的至少一种",
                "熟悉Tomcat等应用服务器中的至少一种",
                "熟悉设计模式并能运用至项目",
                "熟悉消息队列，Redis缓存等相关技术并应用",
                "熟练使用linux基础命令",
                "熟悉使用前端ReactJs,VUEjs等技术",
                "英语四级水平",
                "全日制大专及以上学历，计算机，软件工程等相关专业",
                "有强烈责任心，对数据敏感，性格开朗，善于与人打交道，学习能力好，有在IT领域长期发展的意愿",
                "有docker,k8s经验者优先",
              ],
            },
          },
          {
            name: "运维工程师",
            workplace: "上海",
            salaryRange: "7k-10k/月",
            department: "上海运维部",
            profession: "研发",
            detail: {
              duty: [
                "负责协助所属项目的技术交流、技术答疑工作",
                "负责对第三方使用团队及人员的系统操作培训工作",
                "负责所属项目的平台系统检查、管理、性能指标监测、汇报工作",
                "负责所属项目所在机房的定期设备、环境、网络巡检记录和汇报工作",
                "负责所属项目的技术客服及运营人员的系统操作流程及技术知识培训、指导工作",
                "负责协助运营部完成运营报表所需数据的提取工作；负责协助产品部完成项目二次需求确认工作",
                "负责协助技术支撑工程师完成平台系统升级、测试、漏洞修复及优化工作",
                "负责所属项目周期性运维工作汇总报告",
              ],
              claim: [
                "熟悉Linux操作系统操作及应用",
                "熟悉硬件服务器及网络交换机的硬件设备的系统安装及维护",
                "熟悉Mysql、Oracle数据库的应用",
                "熟悉网络基本原理",
                "了解呼叫中心业务流程",
                "对外具备一定语言表达能力",
              ],
            },
          },
          {
            name: "运营经理",
            workplace: "根据业务城市分配",
            salaryRange: "6k-8k/月",
            department: "话加事业部",
            profession: "研发",
            detail: {
              duty: [
                "了解外呼平台各项操作与流程，协助省办经理落地当地外呼项目",
                "协助省办经理落实各项计划，跟进各环节进度，促进各类营销政策及制度有效实施执行",
                "基础报表制作与反馈，根据省办经理及局方要求，按照相关格式定期反馈报表内容",
                "对市场外部环境变化及内部问题产生要有及时的数据分析，对各业务部门，环节要有一定了解，精准做出对应整改",
                "对项目顺利运营所涉及到的各个部门实时沟通，扫清发展障碍",
                "对本地团队的企业文化建设、凝聚力建设跟进，发扬公司良好的企业文化",
                "推动完成公司下发的各项KPI考核指标，按时限要求完成公司领导临时交派的任务",
                "负责本区域内既定的业务规划分阶段监督实施",
              ],
              claim: [
                "熟悉当地运营商市场环境",
                "有过电话营销运营管理经验",
                "熟悉运营商产品政策",
                "能够适应短期出差",
                "具备较强的统筹规划能力",
                "开发速度快、抗压能力强，可应对较大的工作压力",
              ],
            },
          },
          {
            name: "商务经理",
            workplace: "根据业务城市分配",
            salaryRange: "8k-13k/月",
            department: "话加事业部",
            profession: "事业",
            detail: {
              duty: [
                "负责运营商移动或联通业务拓展工作，商务政策的谈判、框架协议的签订及合作项目的推进",
                "协调对接广东省区域地级市的运营商相关政策，做好协调执行工作",
                "负责维系运营商客户关系，与联通对外商务联络及商务接待",
                "负责商务项目招标工作",
                "据总公司要求，按计划完成公司拓展运营商的业绩指标，规范商务业务的流程，对收入负责",
                "负责运营商业务相关数据的整合分析，收集整合运营商客户信息资源，有效进行成本管控",
              ],
              claim: [
                "形象气质佳、情商高",
                "大专学历以上，年龄26-35岁",
                "3年以上对外工作经验",
                "具备商务谈判、商务礼仪专业技巧，能进行商务接待",
                "较强的亲和力和表达能力，较好的气质和谈吐，思维敏捷，具有较高的商务谈判技巧",
                "服从管理",
              ],
            },
          },
        ],
        copyTableList: [],
        expandIndex: 0,
        lifeList: [
          [
            require("@/assets/images/life/life1.png"),
            require("@/assets/images/life/life17.png"),
            require("@/assets/images/life/life3.png"),
            require("@/assets/images/life/life4.png"),
          ],
          [
            require("@/assets/images/life/life5.png"),
            require("@/assets/images/life/life6.png"),
            require("@/assets/images/life/life7.png"),
            require("@/assets/images/life/life8.png"),
          ],
          [
            require("@/assets/images/life/life9.png"),
            require("@/assets/images/life/life10.png"),
            require("@/assets/images/life/life11.png"),
            require("@/assets/images/life/life12.png"),
          ],
          [
            require("@/assets/images/life/life16.png"),
            require("@/assets/images/life/life13.png"),
            require("@/assets/images/life/life14.png"),
            require("@/assets/images/life/life15.png"),
          ],
        ],
        lifeListArray: [
          [require("@/assets/images/life/life1.png")],
          [require("@/assets/images/life/life17.png")],
          [require("@/assets/images/life/life3.png")],
          [require("@/assets/images/life/life4.png")],
          [require("@/assets/images/life/life5.png")],
          [require("@/assets/images/life/life6.png")],
          [require("@/assets/images/life/life7.png")],
          [require("@/assets/images/life/life8.png")],
          [require("@/assets/images/life/life9.png")],
          [require("@/assets/images/life/life10.png")],
          [require("@/assets/images/life/life11.png")],
          [require("@/assets/images/life/life12.png")],
          [require("@/assets/images/life/life16.png")],
          [require("@/assets/images/life/life13.png")],
          [require("@/assets/images/life/life14.png")],
        ],
      },
    };
  },
  filters: {
    titleFormat(data, index) {
      const arr = data.split("：");
      return index === 0 ? arr[index] + "：" : arr[index];
    },
  },
  mounted() {
    this.$nextTick(() => {
      let wow = new WOW.WOW({
        boxClass: "wow",
        animateClass: "animated",
        offset: 0,
        mobile: true,
        live: false,
      });
      wow.init();
    });
  },
  created() {
    this.source.copyTableList = JSON.parse(
      JSON.stringify(this.source.tableList)
    );
  },
  methods: {
    //名称发生改变
    PostNameInput() {
      this.profession = [];
      this.depart = [];
      this.address = [];
      this.searchFrom.professionType = null;
      this.searchFrom.depart = null;
      this.searchFrom.address = null;
      if (this.searchFrom.postName === "") {
        this.source.tableList = this.source.copyTableList; //将一开始拷贝的值赋值给原来的数组
      }
      this.profession = this.products.filter(
        (item) =>
          this.searchFrom.postName !== "" &&
          item.name.includes(this.searchFrom.postName)
      );
    },
    //职能类别发生改变
    ProfessionChange() {
      this.depart = [];
      this.address = [];
      this.searchFrom.depart = null;
      this.searchFrom.address = null;
      this.depart = this.profession.filter(
        (item) =>
          this.searchFrom.professionType !== "" &&
          item.type.includes(this.searchFrom.professionType)
      );
    },
    //所属部门发生改变
    departChange() {
      this.address = [];
      this.searchFrom.address = null;
      this.address = this.depart.filter(
        (item) =>
          this.searchFrom.depart !== "" &&
          item.depart.includes(this.searchFrom.depart)
      );
    },
    btn() {
      // console.log(this.searchFrom)

      const { postName, professionType, depart, address } = this.searchFrom;
      if (this.searchFrom.v_radio == 1 || postName == "" || postName == null) {
        //如果当前显示的是校园招聘就不给输出 还有未输入东西就搜索
        return;
      }
      let reslut = this.source.copyTableList.filter((item) => {
        if (postName && professionType && depart && address) {
          if (
            item.name.includes(postName) &&
            item.profession.includes(professionType) &&
            item.department.includes(depart) &&
            item.workplace.includes(address)
          ) {
            return item;
          }
        } else if (postName && professionType && depart) {
          if (
            item.name.includes(postName) &&
            item.profession.includes(professionType) &&
            item.department.includes(depart)
          ) {
            return item;
          }
        } else if (postName && professionType) {
          if (
            item.name.includes(postName) &&
            item.profession.includes(professionType)
          ) {
            return item;
          }
        } else if (postName) {
          //用户输入了第一个值

          if (item.name.includes(postName)) {
            return item;
          }
        }
      });
      this.source.tableList = reslut;
    },
    onZhaopinClick(index) {
      this.zhaopinActive = index;
    },
    onExpand(index) {
      this.boxShow = index;
      this.source.expandIndex = index;
    },
    onCollapse(index) {
      this.boxShow = ""; //注意大小写    vscode是不会提示你的 webstorm是会告诉你的  收起时不添加类 所以 = ''就行了
      if (this.source.expandIndex == index) {
        this.source.expandIndex = undefined;
      } else {
        this.source.expandIndex = index;
      }
    },
    onLifePrev() {
      this.$refs.life.prev();
    },
    onLifeNext() {
      this.$refs.life.next();
    },
  },
};
</script>
<style lang="less" scoped>
@width: 50px;
@height: 60px;
.components-about-hdJoin {
  .activeItem {
    background-color: #ffffff;
    box-shadow: 0px 0px 20px 0px rgba(31, 99, 210, 0.1);
    border-radius: 30px;
    border: unset !important;
  }
  .Process {
    padding: 0.3125rem 0;
    margin-bottom: 0.9125rem;
    h1 {
      font-size: 0.45rem;
      font-weight: bold;
      // // color: #333333;
      margin-bottom: 50px;

      color: #333333;
    }
  }
  .lifeContent {
    margin: 25px 0;
    h1 {
      font-size: 0.5rem;
      font-weight: 700;
      color: #333333;
    }
  }
  .recruiTment {
    margin-bottom: 62px;
    margin-top: 50px;
    h1 {
      font-size: 0.45rem;
      font-weight: 700;
      color: #333333;
    }

    .search-btn-box {
      cursor: pointer;
      display: flex;
      align-items: center;
      /*.img {*/
      /*  /deep/.el-image__inner {*/
      /*    vertical-align: middle;*/
      /*  }*/
      /*}*/
    }
  }
  .zhaopinList {
    display: flex;
    justify-content: space-between;
    margin: 0 auto 0.78125vw !important;
    .zhaopinItem {
      position: relative;
      cursor: pointer;
      font-size: 0px;
      .el-image {
        width: 6.25vw;
        height: 6.25vw;
      }
      &.arrow {
        .el-image {
          overflow: visible;
          font-size: 0px;
          &::before {
            right: -150%;
            width: 1.25rem;
            height: 0.5rem;
            content: "";
            position: absolute;
            top: 50%;
            transform: translate(50%, -50%);
            background: url("../../assets/arrow.png") left no-repeat;
            background-size: 50% 50%;
          }
        }
      }

      &.active {
        &::after {
          content: ":";
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          color: #faea3e;
          font-size: 0.375rem;
          font-weight: bold;
        }

        p {
          color: #487cd9;
        }
      }

      p {
        font-weight: bold;
        font-size: 0.275rem;
        margin: 1.5625vw 0 0.375rem;
        // margin: 35px 0;
      }
    }
  }

  .contentList {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 3.4rem;
    background: url(../../assets/images/Newculture.jpg) center no-repeat;
    background-size: 100% 100%;
    width: 90%;
    margin-left: 6%;
    height: 3.15rem;

    h1 {
      font-size: 0.375rem;
      margin: 0.25rem 0;
      font-weight: bold;
      color: #487cd9;
    }

    p {
      margin: 0.125rem 0;
      font-size: 0.2rem;

      color: #333333;

      a {
        color: #487cd9;
      }
    }
  }

  .el-form {
    .zhaopin-from {
      margin-top: -40px;
      width: 100%;
      .radioClass {
        margin: 50px 0 5px;
        .is-active {
          box-shadow: none;
        }
      }
    }

    .el-form-item {
      margin-right: 0.4625rem;
    }

    .el-radio-button {
      margin: 40px;

      /deep/ .el-radio-button__inner {
        border: 1px solid #dcdfe6;
        border-radius: 30px;
        width: 2.125rem;
        font-size: 0.25rem;
        padding: 12px 12px;
        // border-left: 1px solid #dcdfe6;
      }

      /deep/ .el-radio-button__orig-radio {
        &:checked {
          & + .el-radio-button__inner {
            background-color: #1f63d2;
            box-shadow: none;
          }
        }
      }
    }

    /deep/ .el-input__inner {
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-radius: 0;
    }
  }

  .hd-table {
    margin: 20px 0;
    width: 90%;
    margin-left: 6%;

    .hd-tableHead {
      .hd-tr {
        .tr-body {
          background-color: #1f63d2;
          color: #fff;
        }
      }
    }

    .hd-tr {
      &.active {
        box-shadow: 0 0 2px 2px #e9e9e9;
      }

      .tr-body {
        width: 100%;
        display: flex;
        align-items: center;
        height: 48px;
        line-height: 48px;
        border-radius: @height / 2;
        padding: 0 20px;
        font-weight: 500;
        background: #ffffff;
        border: 1px solid #e5e5e5;

        & > div {
          width: 22%;
          font-size: 0.225rem;

          &.btns {
            span {
              display: inline-block;
              cursor: pointer;
              width: 1.875rem;
              height: @height / 2;
              line-height: @height / 2;
              border-radius: @height / 2;

              &:hover {
                background-color: #1f63d2;
                color: #fff;
              }

              &.collapseBtn {
                background-color: #1f63d2;
                color: #fff;
              }
            }
          }
        }
      }

      .other {
        margin: @height / 2 0;
        background: url(../../assets/images/foot-bg.jpg) center no-repeat;
        background-size: cover;
        text-align: left;
        padding: @height;

        .h2 {
          font-weight: bold;
        }

        .duty,
        .claim {
          .fontSize {
            padding: 10px;
            font-weight: bold;
            font-size: 0.225rem;
          }

          margin: @height / 2 0;

          li {
            padding: 10px;
            font-size: 0.2rem;
          }
        }
      }
    }

    .hd-tableBody {
      .hd-tr {
        margin: 20px;

        .tr-body {
          padding: 0 20px;
          & > div {
            &:last-child {
              width: 40%;
            }
          }
          .btns-sm {
            width: 10px !important;
          }
        }
      }
    }
  }

  .life-wrapper {
    margin: 0.625rem 0;
    height: 13.8021vw;

    /deep/ .el-carousel__container {
      height: 100% !important;
      .lifeList {
        display: flex;
        justify-content: space-between;
        width: 90%;
        margin-left: 6%;

        .lifeItem {
          width: 23%;
          background: #f1f8ff;
          height: 100%;

          img {
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }

  .buleText {
    color: #487cd9;
  }

  /deep/.el-icon-arrow-up:before {
    content: "\e78f" !important;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    color: #333;
    transform: translate(-50%, -50%);
  }
  .infoms {
    margin-top: 0.75rem;
    li {
      font-size: 0.225rem;
      font-weight: bold;
    }
  }
}

.item_opt {
  .opt-btns {
    top: auto;
    bottom: 0;
    transform: none;
    height: auto;

    .el-button {
      color: #4887d9;

      display: flex;
      align-items: center;
      justify-content: center;
      &:first-child {
        top: auto;
        bottom: 0;
        left: -25px;
        border-radius: 50%;
        height: 43px;
        width: 43px;
        padding-left: 18px;
      }

      &:last-child {
        left: 30px;
        border-radius: 50%;
        height: 43px;
        width: 43px;
        padding-right: 18px;
      }
    }
    .el-button:focus {
      background-color: #e5f0fc;

      color: #fff;
      border-color: #e5f0fc;
    }
    .el-button:hover {
      background-color: #1f63d2;
    }
  }
}

@media screen and (max-width: 768px) {
  .sm_screen {
    // padding: 0.5rem  /* 40/80 */;
    &.active {
      &::after {
        content: ":";
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateY(-50%);
        color: #faea3e;
        font-size: 0.375rem;
        font-weight: bold;
      }
    }
  }
  .components-about-hdJoin {
    .el-row {
      margin: 25px 0;
    }
    .Process {
      margin-top: -60px;
    }
    h1 {
      font-size: 28px;
    }

    .searchBtn-container {
      display: flex;
      justify-content: center;
    }

    /deep/ .el-radio-button__inner {
      font-size: 20px;
    }

    /deep/ .el-input--suffix .el-input__inner {
      // padding-right: 60px;
    }

    .zhaopinItem {
      p {
        font-size: 16px;
      }
    }
    .hd-table {
      width: 100%;
      .hd-tr .tr-body > div {
        width: 25% !important;
        font-size: 12px !important;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        &.profession {
          text-align: left;
          text-indent: 5vw;
        }
      }
    }
  }
}
@media screen and (max-width: 375px) {
  .components-about-hdJoin .hd-table .hd-tr .tr-body {
    line-height: 20px !important;
  }
  .hd-tr .other {
    padding: 10px !important;
  }
  .el-form {
    .el-form-item {
      margin-right: 0 !important;
    }
  }
}
</style>
