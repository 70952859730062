export default {
    data: function () {
        return {
            cityList: [
                {
                    cityname: "江苏",
                    title: "南京办事处",
                    address: "江苏省南京市雨花台区花神大道23号京妆商务5号楼406室",
                    tel: "025-86809997"
                }, {
                    cityname: "河北",
                    title: "河北办事处",
                    address: "河北省石家庄市长安区广安大街6-8号铂金公馆1917",
                    tel: "13111520115"
                }, {
                    cityname: "河南",
                    title: "河南办事处",
                    address: "河南省郑州市金水区未来路街道金水路229号河南信息广场B座7楼714",
                    tel: "15637116305"
                }, {
                    cityname: "湖北",
                    title: "湖北办事处",
                    address: "湖北省武汉市东西湖区碧海花园金银湖北街8号金银湖创新中心702室",
                    tel: "027-59339099"
                }, {
                    cityname: "湖南",
                    title: "湖南办事处",
                    address: "湖南省长沙市雨花区万家丽中路二段429号湖南联通办公楼6楼612室",
                    tel: "1867000594"
                }, {
                    cityname: "广西",
                    title: "广西办事处",
                    address: "广西壮族自治区南宁市青秀区南湖街道金浦路9号联通大厦(金浦路)",
                    tel: "18677178223"
                }, {
                    cityname: "江西",
                    title: "江西办事处",
                    address: "江西省南昌市火炬大街566号联通广场201室",
                    tel: "15617631355"
                }, {
                    cityname: "四川",
                    title: "四川办事处",
                    address: "四川省成都市武侯区蜀绣西路18号中国联合网络通信有限公司四川分公司8楼",
                    tel: "18302812066"
                }, {
                    cityname: "陕西",
                    title: "陕西办事处",
                    address: "陕西省西安市雁塔区沣惠南路15号陕西联通大厦",
                    tel: "15691999868"
                }, {
                    cityname: "吉林",
                    title: "吉林办事处",
                    address: "吉林省长春市二道区四通路御景名都B3-3单元",
                    tel: "17643077778"
                }, {
                    cityname: "鹤壁",
                    title: "鹤壁基地",
                    address: "河南省鹤壁市淇滨区卫河路与通用巷交叉口东南角28号写字楼3楼",
                    tel: "15637116305"
                }, {
                    cityname: "衡水",
                    title: "衡水基地",
                    address: "河北省衡水市武邑县职业技术教育中心（北门）",
                    tel: "031-85918899"
                }, {
                    cityname: "肇庆",
                    title: "肇庆基地",
                    address: "广东省肇庆市端州区天宁北路75号广发大厦12楼",
                    tel: "15813977218"
                }
                , {
                    cityname: "镇江",
                    title: "镇江基地",
                    address: "江苏省镇江市润州区城区中山西路98号外贸大楼3楼",
                    tel: "16602125202"
                },{
                    cityname: "武汉",
                    title: "武汉基地",
                    address: "湖北省武汉市东西湖区金银湖北街8号金银湖创新中心702室",
                    tel: "027-59339099"

                }

            ]
        }
    }
}